import React, { useState, useContext } from 'react';
import useToggle from './fungsi/useToggle';
import { MDBMultiCarousel, MDBMultiCarouselItem } from 'mdb-react-multi-carousel';
import { MDBLightbox } from 'mdb-react-ui-kit';
// props : 
export function Iklan (props)  {
    const [error, onError] = useToggle()

   // const src = props.img
  //  const username = props.name
 //console.log ("iklan")
    return (
        <MDBLightbox>
            <MDBMultiCarousel items={1} interval={3000} className="mb-5" lightbox >

            <MDBMultiCarouselItem
                    src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/ibwnews/img/iklan/2.jpg'
                alt='Iklan Anda Disini'
            />
            <MDBMultiCarouselItem
                src='https://via.placeholder.com/320x90/0000FF/808080?text=Space+Untuk+Iklan'
                    alt='Iklan Anda Disini'
            />

            <MDBMultiCarouselItem
                    src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/ibwnews/img/iklan/3.jpg'
                    alt='Iklan Anda Disini'
            />


            <MDBMultiCarouselItem
                    src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/ibwnews/img/iklan/1.jpg'
                    alt='Iklan Anda Disini'
            />
 
            <MDBMultiCarouselItem
                src='https://via.placeholder.com/320x90/FFFF00/000000?text=Space+Untuk+Iklan'
                    alt='Iklan Anda Disini'
            />
            <MDBMultiCarouselItem
                    src='https://ibwfiles.s3.ap-southeast-1.amazonaws.com/ibwnews/img/iklan/4.jpg'
                    alt='Iklan Anda Disini'
            />
        </MDBMultiCarousel>
            </MDBLightbox>
    )
}// JavaScript source code


