import React, { useRef, useState, useEffect,useContext, Fragment } from 'react';
import {
    MDBContainer, MDBRow, MDBCol, MDBIcon, MDBAlert, MDBListGroupItem, MDBListGroup, MDBRating, MDBCardGroup, MDBCardTitle, MDBCardFooter,
  MDBRatingElement,MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBBtn, MDBTextArea, MDBInput,MDBBadge } from "mdb-react-ui-kit";
import { Dialog } from './Dialog.js'
import {Text } from './Text'
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext'
import { useHistory } from "react-router-dom";
import { QuoteList } from './QuoteList';
export function Survey(props) {

    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const [isform, setisform] = useState(false);
    const { user, changeUser } = useContext(UserContext)
    const isAuth = user.islogedin;
    const [hasData, sethasData] = useState(false);
    const [Loading, setLoading] = useState(true);
    const [isloading, setIsLoading] = useState(false);
    const [haserror, setHasError] = useState(false);
    const [pesanerror, setPesanError] = useState('');
    const [dataQuiz, setdataQuiz] = useState([])
    const [dataSurvey, setdataSurvey] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [idSurvey, setidSurvey] = useState(0);
    const [jawaban, setJawaban] = useState('');
    const [notAuth, setNotAuth] = useState(!user.islogedin)
    let history = useHistory();
    const defaultValues = {
        jawaban: "",
        usiaanak:"0"
    };
    const param = props.match.params.aksi && props.match.params.aksi

    useEffect(() => {
        const param = props.match.params.aksi && props.match.params.aksi
        setidSurvey( props.match.params.id||1)
        setisform(false);
        switch (param) {
            case "checking":
                setisform(true);
                // code block
                break;
            case "list":
             
                // code block
                break;
            case "":
                break;
            default:
                setisform(false);
                //console.log("");
            // code block
        }
    }, []);

 
    const Pesan = (props) => {
        const link = props.link
        const judul = props.judul
        return (
            <>

                <MDBBtn outline color="primary" className="mt-4" tag='a' href={link} >
                    {judul}
                </MDBBtn>
            </>
        )
    }
    const saveFormData = async (formValue) => {
      // console.log(dataQuiz); console.log(quizIndex);
        const formNet = {
            ...formValue, idsurvey: dataQuiz[quizIndex].iddialog, "nama": user.nama, "membernama": user.nama, "memberid": user.id, "poin": 10, "pilihanrating": dataQuiz[quizIndex].pilihanrating.join()
        }
        //   console.log(JSON.stringify(formValue));

        //   setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   })
        //  console.log(JSON.stringify(formValue));
        setIsLoading(true); //console.log(jawaban)
        const response = await fetch('https://ibw.news/simpansurveyjawab', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            sethasData(false);
            setIsLoading(false);
            setHasError(true);
            throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        sethasData(true);
        setdataSurvey(data);
        setJawaban(data.jawaban);
        console.log(data);
       // reset({ defaultValues });

        //changeUser({ ...data[0] });

    }
    const saveRating = async (formValue) => {
        //console.log(dataQuiz); console.log(quizIndex);
        const formNet = {
            ...formValue, idsurvey: dataQuiz[quizIndex].iddialog, "nama": user.nama, "membernama": user.nama, "memberid": user.id, "poin": 10
        }
        //   console.log(JSON.stringify(formValue));

        //   setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   })
        //  console.log(JSON.stringify(formValue));
       // setIsLoading(true); //console.log(jawaban)
        const response = await fetch('https://ibw.news/simpansurveyrating', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
        //    sethasData(false);
        //    setIsLoading(false);
            setHasError(true);
            throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        sethasData(true);
        setdataSurvey(data);
        
    //    setJawaban(data.jawaban);
        // reset({ defaultValues });

        //changeUser({ ...data[0] });

    }
    function isOdd(num) { return (num % 2) == 1; }
    const PilihRating = (props) => {
        return (
            <div className="d-flex  justify-content-end" > 
                <MDBRating defaultValue={props.value} onChange={props.onChange}   >
                    <MDBRatingElement itemId={1} icon='heart'  />
                <MDBRatingElement itemId={2} icon='heart' />
                <MDBRatingElement itemId={3} icon='heart' />
                <MDBRatingElement itemId={4} icon='heart' />
                    <MDBRatingElement itemId={5} icon='heart' />
                </MDBRating>
              
            </div>
            )
    }
    const onSubmit = async (data) => {

        try {
            //setSubmit(true);
            setHasError(false);
            await saveFormData(data);
           // console.log(data)
            const sem = watch("jawaban");
         //   console.log(sem)
            setJawaban(sem);
            setIsLoading(false);
            setPesanError('Jawaban Sudah Disimpan');
          //  dataQuiz[quizIndex].isdone = true;
           // dataQuiz[quizIndex].jawaban = sem;
            setJawaban("");
            reset({ defaultValues })

        } catch (e) {
            setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            setIsLoading(false);
            setHasError(true);
          //  reset({ defaultValues })
        }
    }
    useEffect(() => {
        const hasil = { "jawaban": "", "idsurvey": idSurvey }
        saveFormData(hasil);
    }, [dataQuiz]);

    const JawabanSurveyList = (props) => {
        const d = props.data
        const i=props.index
        const isKiri = isOdd(i);
        const kelas = isKiri ? "d-flex justify-content-start w-100" : "d-flex justify-content-end ms-auto";
      // console.log (d)
        
        return (
            <MDBRow className='row-cols-1 row-cols-md-3 g-4'>
   {  d.map((p, i) => 
           
    <MDBCol key={"jawabsurvey"+i}>
       
           <MDBCard className="h-100"  >
               <MDBCardHeader className="text-center">
                   {(p.hasilrating.length > 0) ? (p.hasilrating.map((a, i) =>
                       <PilihRating key={"pilihrating" + i}  value={a.value} onChange={(e) => { if (e !== a.value) { saveRating({ "pilihan": a.label, "rating": e, "idjawaban": p.id }) } }} />
                   )) : <PilihRating key={"pilihrating" + i} value="0" onChange={(e) => { if (e > 0) { saveRating({ "rating": e, "idjawaban": p.id }) } }} />
                  
                   }
               </MDBCardHeader>
                    <MDBCardBody>
                      
                        <MDBCardTitle>[ {p.usiaanak} tahun ]</MDBCardTitle>
                        <MDBCardText>
                       <MDBIcon fas icon="quote-left" /> {p.jawaban} 
        
                                   
                       <small className="text-muted">  ( {p.membernama}  ) </small> 
         
                       </MDBCardText>
                            
               </MDBCardBody>
     
 </MDBCard>
                          
                  
                    </MDBCol>
                   
                   
                    ) }



            </MDBRow>
        
            
           
            )

    }
    function DetilQuiz(props) {
        const d = props.data;
        const [u, setU] = useState(d);
        //   useEffect(() => setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": jawaban, "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   }), [1]);

        const inputEl = useRef(null);

       // console.log("sampai detil Quiz");

        return (
            <MDBRow key={"detil"+props.index} className={props.index === quizIndex ? " row d-flex justify-content-center " : "d-none  row d-flex justify-content-center  "}  >
                <MDBCol className="px-4 py-5 px-md-5  text-center">
                    <MDBCard className="text-center">
                        <MDBCardHeader className="p-4">
                            <h5 className="mb-0">
                                {d.isdone && <MDBIcon className="mx-2" fas icon="check" />}
                                <strong>{d.judul}</strong>
                                <MDBBadge color={d.poin > 50 ? 'warning' : 'success'} className='ms-2'>{d.poin} poin</MDBBadge>

                            </h5>
                        </MDBCardHeader>
                        <MDBCardBody className="p-2">
                            <p>   <a href={d.urltwibbon}  >
                                {d.urltwibbon}
                            </a>
                            </p>
                            <p className="mb-1">{d.tanya}</p>
                            {d.isdone && "jawaban : " + d.jawaban}
                            {d.pilihanrating.map((a, i) => i > 0 ? <span key={"spanrating" + i}> | {a} </span> : (<span key={"spanrating" + i}> {a} </span>) )  }

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

            </MDBRow>

        );
    }
    useEffect(() => {
        const userid = !notAuth ? user.id : 0
        function populateData() {
            const response = fetch('getsurvey/' + (userid)).then(
                res => {
                    if (res.ok) return res.json();
                    sethasData(false);
                    throw res;
                }
            ).then(data => {
               // console.log(data);
                if (data.length > 0 ) { setdataQuiz(data); sethasData(true); setHasError(false);    }
                else {
                    sethasData(false); setPesanError("Saat ini tidak ada quiz di web"); setLoading(false);
                    setHasError(true); }

            }
            ); //

        }
      
        populateData();  
    }
        , []);

    

    return (<>
        <h2 className="fw-bold mx-5 text-center">Cek Kasus</h2>
        <div className="square border  border-warning  rounded-3" >
        <MDBRow className=" gx-lg-5 align-items-center"  >
            <MDBCol className="col-12 col-lg-5 mb-5 mb-lg-0">
                <div className='bg-image  rounded-5 shadow-5-strong'>
                    <img src='https://mdbootstrap.com/img/new/standard/city/053.webp' className='w-100' />
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            {hasData && dataQuiz.map((d, i) => (

                                <DetilQuiz index={i} key={"detilquiz" + i} data={d} />

                            ))}
                        </div>

                    </div>
                </div>
            </MDBCol>
            <MDBCol className="col-12 col-lg-7 mb-4 mb-lg-0">

        {(isform & hasData & user.islogedin) && 
               
                <MDBCard shadow='0'  background='white' className='mb-2 w-100' >
                    <MDBCardHeader>Tuliskan kira2 reaksi jika berada di situasi tersebut  </MDBCardHeader>
                    <MDBCardBody >
                        <div className="d-grid gap-1  mx-auto mb-1">
                            <form onSubmit={handleSubmit(onSubmit)} className="my-1" >

                                <Controller
                                        name="jawaban"
                                        defaultValue=""
                                    control={control}
                                    rules={{ required: true, maxLength: 255 }}
                                        render={({ field }) => <MDBTextArea row={4 } className="mb-2" label="Jawaban" {...field} />}
                                />
                                {errors.jawaban?.type === 'maxLength' && "Batas 255 karakter"}
                                <Controller
                                    name="usiaanak"
                                    defaultValue=""
                                    control={control}
                                    rules={{ required: true, maxLength: 255 }}
                                    render={({ field }) => <MDBInput  className="mb-2" label="Usia Anak (dalam tahun)" {...field} />}
                                />
                                {errors.usiaanak?.type === 'required' && "Usia anak harus diisi"}
                        
                                <MDBBtn type="submit" rounded disabled={!isAuth}  ><Text tid="save" /> </MDBBtn>
                            
                            </form>

                                {(haserror ) &&
                                    <>
                                        <MDBAlert show className='w-100' color='warning'>
                                            {pesanerror}
                                   </MDBAlert>
                                    </>
                                }

                        </div>
                        <MDBCardText>
                          
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                     } 
            </MDBCol>
        </MDBRow>
   </div>


        <div className='my-3 text-center'>

            <JawabanSurveyList data={dataSurvey} />
              
        </div>
  </>
)


    
  
}






