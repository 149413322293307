import React from 'react';
import { MDBSpinner } from "mdb-react-ui-kit"

export function Spinner() {
    return (<div className='d-flex justify-content-center'>
        <MDBSpinner className='mx-2 text-center' color='warning'>
            <span className='visually-hidden'>Loading...</span>
        </MDBSpinner>
    </div>)

}// JavaScript source code
