import React, { useState, useContext, useEffect, useRef ,useReducer } from 'react';
import UserContext from './konteks/userContext'
import {
    MDBContainer,
    MDBBtn,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,
    MDBSpinner, MDBCheckbox,
    MDBInput, MDBIcon,
    MDBRow, MDBBadge,
    MDBCol, MDBAlert
} from "mdb-react-ui-kit";
const a = [{
    "iddialog": 1,"judul":"Tuliskan akun Instagram", "tanya": "Apakah flexing (pamer kekayaan di media sosial) itu perlu? ",
    "tipeaksi": "jawab",
    "urltwibbon": "",
    "urlartikel": "https://ibw.news/artikel/2"
}]
import { Spinner} from "./fungsi/Spinner"
import { useMDBStorage } from 'mdb-react-storage-management';
import { useForm, Controller } from "react-hook-form";
import { GrupRekap } from "./GrupRekap";
import reducer from "./konteks/reducer"
import { ButtonAuth } from "./ButtonAuth"

export function TodayQuiz() {

    const [dataQuiz, setdataQuiz] = useState(a)
    const [quizIndex, setQuizIndex] = useState(0);
    const {user, changeUser } = useContext(UserContext)
    const [quizDone, { setItem: setQuizDone, removeItem: removeQuizDone }] = useMDBStorage('quizdone', []);

    const [jawaban, setJawaban] = useState('');
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();


    const defaultValues = {
        jawaban: ""
    };
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData:false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan:"warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
   // console.log(state);
    useEffect(() => dispatch({ type: "SET_LOGIN", payload: user.islogedin }) , []);
    const Pesan = (props) => {
        const link = props.link
        const judul=props.judul
        return (
            <>
                <MDBBtn outline color="primary" className="mt-4" tag='a' href={link} >
                    {judul}
                </MDBBtn>
            </>
        )
    }
    useEffect(() => {
      //  dispatch({ type: "SET_LOGIN", payload: user.islogedin });
       // console.log(user);
        const userid= user.islogedin ? user.id : 0
        function populateData() {
              const response =  fetch('getdialogstage/'+ (userid)).then(
                    res => {
                        
                        if (res.ok)  return res.json();
                       
                      //  sethasData(false);
                       
                        throw res;
                    }
                ).then(data => {
                    
                    if (data.length > 0) { setdataQuiz(data); dispatch({ type: "SET_DATA", payload: data }) }//sethasData(true);console.log(data);
                    else { dispatch({ type: "HAS_ERROR",payload:"Gagal Ambil Data" }) } //sethasData(false); setHasError(true); console.log('pusinggg');
                   // setLoading(false);
                    
      
                 
                   // setPesanError( "Saat ini tidak ada quiz di web");
                }
                );

        }
       // dispatch({ type: "SET_LOGIN", payload: user.islogedin });
            populateData();
        } 
        , [user]);

    function DetilQuiz(props) {
        const d = props.data;
 
     //   useEffect(() => setFormValue({
     //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": jawaban, "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
     //   }), [1]);
    
        const inputEl = useRef(null);
      //   console.log(d) 
  
       const  tabungPoin=(id, poin) =>{


            const arr = quizDone || [];
            arr.push({ id: id, value: id })

            const uniqueIds = new Set();
            const unique = arr.filter(element => {
                const isDuplicate = uniqueIds.has(element.id);

                //  uniqueIds.add(element.id);

                if (!isDuplicate) {
                    uniqueIds.add(element.id);
                    return true;
                }

                return false;
            });
            //dataQuiz[id].isdone = true;
            setQuizDone(unique, 1);

            //  console.log(dataQuiz[id]);
        }
  

        return (<>

            <MDBRow key={"detilquiz"+props.index} className={props.index === quizIndex ? " row d-flex justify-content-center " : "d-none  row d-flex justify-content-center  "}  >
                <MDBCol className="mx-2">
                    <MDBCard className="text-center">
                        <MDBCardHeader className="p-4">
                            <h5 className="mb-0">
                                {d.isdone && <MDBIcon className="mx-2" fas icon="check" />}
                                <strong>{d.judul}</strong>  
                                <MDBBadge color={d.poin > 50 ? 'warning' : 'success'} className='ms-2'>{d.poin} poin</MDBBadge>
                               
                            </h5>
                        </MDBCardHeader>
                        <MDBCardBody className="p-4">
                            <p>   <a href={d.urltwibbon}  >
                                {d.urltwibbon}
                            </a>
                            </p>
                            <p className="mb-4">{d.tanya}</p>
                            {d.isdone && "jawaban : " + d.jawaban }

                       
                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>

            </MDBRow>
         </>   
            );
    }

    const saveFormData = async (formValue) => {
        const formNet = {
            ...formValue, iddialog: dataQuiz[quizIndex].iddialog, "nama":user.nama, "membernama": user.nama  , "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
      }
      //   console.log(JSON.stringify(formValue));

     //   setFormValue({
     //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
     //   })
      //  console.log(JSON.stringify(formValue));
       // setIsLoading(true); //console.log(jawaban)
        dispatch({type:"START_LOADING"})
        const response = await fetch('https://ibw.news/tabungpoin', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
          //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setJawaban(data.jawaban);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }

    const onSubmit = async (data) => {
     
        try {
            //setSubmit(true);
            await saveFormData(data);
            const sem = watch("jawaban");
            console.log(sem)
            setJawaban(sem);
           
           // setPesanError('Jawaban Sudah Disimpan');
            dataQuiz[quizIndex].isdone = true;
            dataQuiz[quizIndex].jawaban = sem;
            setJawaban("");
            reset({ defaultValues })

        } catch (e) {
           // setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            dispatch({ type: "FINISH_LOADING" })
            dispatch({ type: "HAS_ERROR", payload: `Gagal Jawab ${e.message}` })
        }
    }

   // console.log(dataQuiz);
    if (isLoading) return (
        <Spinner/>
        )
 
    function nextQuiz() {
       // console.log(quizIndex);
        setQuizIndex(i => (i + 1) % dataQuiz.length);
    }

    //console.log()    // {console.log(hasData)}
 
    return (<React.Fragment>
     


        <MDBContainer className=" background-radial-gradient  overflow-hidden">
            <MDBRow>
                <MDBCol>
                    <h1 className="my-5 display-3 fw-bold ls-tight" style={{ color: "hsl(218, 81%, 95%)" }}>
                        <p>Yuk Ikutan</p>

                        <p style={{ color: "hsl(218, 81%, 75%)" }}>Challenge Hari Ini</p>
                    </h1>
                    <p className="mb-4 opacity-70" style={{ color: "hsl(218, 81%, 85%)" }}>
                        Dari pikiran yang cerdas dan terlatih , kita dapat mendampingi anak dengan lebih percaya diri. 
                        Hanya di IBW, kita mempergunakan waktu kita yang terbatas untuk senantiasa membuat diri bertumbuh dengan cara yang menyenangkan
                        .
                    </p>

                </MDBCol>
                <MDBCol className="col-lg-6 mb-5 mb-lg-0 position-relative" >
                    <MDBContainer id="radius-shape-1" className="position-absolute rounded-circle shadow-5-strong mx-2"></MDBContainer>
                    <MDBContainer id="radius-shape-2" className="position-absolute shadow-5-strong mx-2"></MDBContainer>
                    <MDBCard className=" bg-glass my-4" style={{
                        backgroundColor: "hsla(0, 0%, 100%, 0.9) !important",
                        backdropFilter: "saturate(200%) blur(25px)"
                    }}>
                        <MDBCardBody className=" px-4 py-5 px-md-5">
                            {hasData && data.map((d, i) => (
                                <>

                                    <DetilQuiz index={i} key={"detilquiz" + i} data={d} />
                                </>
                            ))}
                            {hasData &&


                                <form onSubmit={handleSubmit(onSubmit)} className="my-4" >
                            


                                    <ButtonAuth href="./today" color="primary" block type="submit" disabled={(dataQuiz[quizIndex].isdone)}  > {dataQuiz[quizIndex].isdone && "Sudah "} Tabung Poin </ButtonAuth>
                                    {dataQuiz.length > 1 && <MDBBtn color="none" tag="a" block type="button" onClick={nextQuiz}  > Next Quiz </MDBBtn>}
                                </form>
                            }

                        </MDBCardBody>
                    </MDBCard>
                </MDBCol>
            </MDBRow>
        </MDBContainer>


        <MDBBtn type="button" className="invisible" onClick={() => removeQuizDone()}  > Reset Data </MDBBtn>

        {(hasError && !notAuth) &&
            <>
            <MDBAlert show className='w-100' color='warning'>
                {pesanError}
                <p>
                    <Pesan link="https://instagram.com/stories/indonesian.babywearers" judul="Cek story Instagram IBW" />
                </p>
            </MDBAlert>
           </>
            }

        
        </React.Fragment>
        )
}
