/* eslint-disable react/no-unknown-property */
import React, { Component, useContext, useState, useEffect, useReducer, useRef } from 'react';
import { MDBCardTitle, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Text } from './Text';
import { Spinner } from "./fungsi/Spinner";
import reducer from "./konteks/reducer";
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext';
import { useHistory, useLocation } from "react-router-dom";


export function ArtikelList(props) {
    const { user, changeUser } = useContext(UserContext)
    const param = "list"
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const [dataKu, setDataKu] = useState([])
    const loadData = async (formValue) => {

        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/ambilartikel/' + formValue, {
            method: 'GET'
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataKu(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }
    useEffect(
        () => {
            let timer1 = setTimeout(() => loadData(param), 1000);


            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })

    if (isLoading) return (
        <Spinner />
    )
    return (<>
        <MDBTypography tag='h2' className="fw-bold  mb-5 text-center">Lihat Artikel</MDBTypography>
                          <MDBRow className="row gx-lg-5">
        {dataKu.map((d, i) => (
         
                
                            <MDBCol  key={"artikel-" + i} className="col-lg-4 col-md-12 mb-4 mb-lg-0">
                <MDBCard className='h-100'>

                                    <div className="bg-image hover-overlay ripple" dataRippleColor="light">
                        <img src={d.imgheader} className="img-fluid" alt={d.teaser} aria-controls="#picker-editor"/>
                        {i === 0 && <svg className="position-absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ left: 0, bottom: 0 }}> <path fill="#fff" d="M0,96L48,128C96,160,192,224,288,240C384,256,480,224,576,213.3C672,203,768,213,864,202.7C960,192,1056,160,1152,128C1248,96,1344,64,1392,48L1440,32L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path> </svg>
                        }
                        {i === 1 && <svg className="position-absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ left: 0, bottom: 0 }} > <path fill="#fff" d="M0,288L48,272C96,256,192,224,288,197.3C384,171,480,149,576,165.3C672,181,768,235,864,250.7C960,267,1056,245,1152,250.7C1248,256,1344,288,1392,304L1440,320L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path> </svg>
                        }
                        {i === 2 && <svg className="position-absolute" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" style={{ left: 0, bottom: 0 }}> <path fill="#fff" d="M0,288L48,256C96,224,192,160,288,160C384,160,480,224,576,213.3C672,203,768,117,864,85.3C960,53,1056,75,1152,69.3C1248,64,1344,32,1392,16L1440,0L1440,320L1392,320C1344,320,1248,320,1152,320C1056,320,960,320,864,320C768,320,672,320,576,320C480,320,384,320,288,320C192,320,96,320,48,320L0,320Z"></path> </svg>
    }
                                       </div>
                                      <MDBCardBody>
                                 <MDBCardTitle>{d.judul}</MDBCardTitle>
                                   
                        <MDBCardText className="text-break">     {renderHTML(d.teaser)}

                            <MDBBtn tag="a" className="small text-muted " href={d.iseksternal > 0 && (d.urlartikel.replace("+", "%20"))} color='link' rippleColor='dark' target="_blank"  >
                                {d.iseksternal > 0 && (d.urlartikel.replace("+", "%20"))}
                            </MDBBtn>

                        </MDBCardText>
                        <MDBBtn tag="a" href={"artikel/"+ d.judul }  target="_blank" outline rounded >
                            Baca
                        </MDBBtn>
                  
                  
                   
                                    </MDBCardBody>
                                </MDBCard >
                            </MDBCol>
                                    
              

    

        ))
            }
        </MDBRow>
    </>





    );

}