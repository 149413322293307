import React, { Component, useContext, useState, useEffect, useReducer, useRef } from 'react';
import { MDBContainer, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTextArea, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Helmet } from "react-helmet";
import { Text } from './Text';
import { Spinner } from "./fungsi/Spinner";
import reducer from "./konteks/reducer";
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext';
import { useHistory, useLocation } from "react-router-dom";
import { Iklan } from './Iklan';
import { TikTok } from "react-tiktok";
import { ArtikelHalaman } from './ArtikelHalaman';
import { ArtikelDetail } from './ArtikelDetail';
export function Story(props) {
    const { user, changeUser } = useContext(UserContext)
    const param = props.match.params.id && props.match.params.id
    const halaman = props.match.params.halaman ? props.match.params.halaman:1
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const [dataKu, setDataKu] = useState([])
    const loadData = async (formValue) => {
   
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/ambilartikel/'+ formValue, {
            method: 'GET'
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataKu(data);
        dispatch({ type: "FINISH_LOADING" })
       //changeUser({ ...data[0] });

    }
    useEffect(
        () => {
            let timer1 = setTimeout(() =>loadData(param), 1000);

           
            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );
    //// {d.deskripsi1 && renderHTML(d.deskripsi1)}
    //                        {d.tiktok !== '' && <TikTok url={"https://www.tiktok.com/@heniprasetyorinimpd/video/" + d.tiktok}/>}

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })

    if (isLoading) return (
        <Spinner />
    )
    return (<>

        {dataKu.map((d, i) => (

            <MDBContainer key={"artikel-" + i} className=" my-5">
                <Helmet>
                   <meta name="description" content={d.teaser} />
                    <meta name="og:title" content={d.judul} />
                    <meta name="og:description" content={d.teaser} />
                    <title>{d.judul}</title>
                    <meta name="og:image" content={"https://ibw.news/getmediaaws?namafile="+d.imgheader} />
                </Helmet>
                <MDBRow className="row justify-content-center">
                    <MDBCol className="col-lg-10 col-xl-7">

                        <p className="text-muted text-uppercase small">{d.tags}</p>
                        <MDBTypography tag='h2' className="fw-bold pb-1">{d.judul}</MDBTypography>
                 
                        <div className="mb-1">
                            <div className="d-flex justify-content-between mb-3">
                                <div className="d-flex align-items-center">
                                    <img className="rounded-circle" src="https://ibwfiles.s3.ap-southeast-1.amazonaws.com/ibwnews/img/logoIBWbulet.png" style={{ width: 40, height: 40 }} />
                                    <p className="small mb-0 ms-3">By <a href="#!" className="fw-bold text-reset">Tim Edukasi Indonesian Babywearers</a></p>
                                </div>
                                <div className="d-flex align-items-center">
                                    <a href="#!"><i className="fas fa-share me-2"></i></a>
                                    <a href="#!"><i className="fab fa-facebook me-2"></i></a>
                                    <a href="#!"><i className="fab fa-twitter me-2"></i></a>
                                    <a href="#!"><i className="fas fa-rss"></i></a>
                                </div>
                            </div>
                            <p className="small text-muted mb-0">Published 06/28/2022 11:00 AM</p>
                        </div>
                        {halaman == 1 &&
                            <img itemProp="contentUrl" className="w-100 rounded-6 mb-1" src={d.imgheader} alt="Article image" />
                        }  

                        <div style={{ borderBottom: 2 }}>
                            <ArtikelHalaman artikelid={d.id} halaman={halaman} judul={d.judul}  ></ArtikelHalaman>
                             
                               </div>
                   
                        <Iklan />
                  
           
                   
                  


                    </MDBCol>
                </MDBRow >
            </MDBContainer>


        ))
        }
        </>
  




    );

}