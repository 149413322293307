import React, { Component, useContext, useState, useEffect, useReducer } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTextArea , MDBIcon, MDBView, MDBBtn,MDBInput } from "mdb-react-ui-kit";
import { Text } from './Text'
import { TodayQuiz } from './todayQuizAdaJudul'
import { Spinner } from "./fungsi/Spinner"
import reducer from "./konteks/reducer"
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext'

import { MDBTableEditor } from 'mdb-react-table-editor';
import { useHistory, useLocation } from "react-router-dom";

export function Kepribadian(props) {
    const { user, changeUser } = useContext(UserContext)
    const [isform, setisform] = useState(false);
    let [param,setParam] = useState( props.match.params.aksi && props.match.params.aksi)

    const [dataQuiz, setdataQuiz] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [jawaban, setJawaban] = useState('');
    let history = useHistory();
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    function nextQuiz() {
        // console.log(quizIndex);
        setQuizIndex(i => (i + 1) % dataQuiz.length);
    }
    const defaultVal = {
        id: "0",
        tanya: "",
        menu:"parent",
        judul: '',
       jawaban: "",
        urltwibbon: "",
        urlartikel: "",
        poin:10

    };
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm({defaultValues:defaultVal});
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    useEffect(() => {
        switch (param) {
            case "entri":
                setisform(true);
                // code block
                break;
            case "pilih":
                setisform(false);
                saveFormData(defaultVal);
                  // code block
                break;
            case "":
                break;
            default:
            //   console.log("");
            // code block
        }
    }, []);
    
    const saveFormData = async (formValue) => {
        const formNet = {
            ...formValue,"memberid": user.id,"id" :0,"menu":"parent"
        }
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/simpansurvey', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setdataQuiz(data);
        setisform(false);
        dispatch({ type: "FINISH_LOADING" })
        setParam("pilih");
        //changeUser({ ...data[0] });

    }
    const hapusData = async (formValue) => {
        const formNet = {
            ...formValue, "memberid": user.id
        }
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/deletesurvey/'+formNet.id, {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setdataQuiz(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }
    const updateData = async (formValue) => {
        const formNet = {
            ...formValue, "memberid": user.id, "isactive": formValue.isactive  ? 1 : 0
        }
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/updatequiz/' + formNet.id, {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setdataQuiz(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }

    const onSubmit = async (data) => {

        try {
            //setSubmit(true);
            await saveFormData(data);
      //      const sem = watch("jawaban");
           // console.log(sem)
        //    setJawaban(sem);

            // setPesanError('Jawaban Sudah Disimpan');
         //   dataQuiz[quizIndex].isdone = true;
        //    dataQuiz[quizIndex].jawaban = sem;
       //     setJawaban("");
            reset({ defaultVal })

        } catch (e) {
            // setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            dispatch({ type: "FINISH_LOADING" })
            dispatch({ type: "HAS_ERROR", payload: `Gagal Jawab ${e.message}` })
        }
    }

    // console.log(dataQuiz);
    if (isLoading) return (
        <Spinner />
    )
    function TabelKu(props) {
        const [loading, setLoading] = useState(false);

        const [asyncData, setAsyncData] = useState({
            columns: [
                { label: 'id', field: 'id' ,  inputType: 'number' },
                { label: 'pertanyaan', field: 'tanya' },
                { label: 'jawaban', field: 'jawaban' },
                { label: 'Twibbon', field: 'urltwibbon' },
                { label: 'Artikel', field: 'urlartikel' },
                {
                    label: 'isactive', field: 'isactive', inputType: 'number', width: 50,
                    defaultValue: false },
            ],
            rows: props.data,
        });

        useEffect(() => {
            if (loading && asyncData.rows.length === 0) {
                fetch('https://ibw.news/simpanquiz', {
                    method: 'POST', headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(defaultVal)
                })
                    .then((response) => response.json())
                    .then((data) =>
                          setTimeout(() => {
                            setAsyncData({
                                columns: asyncData.columns,
                                rows: data.map((d,i) => ({
                                    ...d,"isactive":d.isactive===1?true:false
                                })),
                            });
                        }, 3000)
                    );
            }
        }, [loading, asyncData.columns]);

        useEffect(() => {
            if (asyncData.rows.length === 0) {
                setLoading(true);
            } else {
                setLoading(false);
            }
        }, [asyncData.rows]);

        const [modalData, setModalData] = useState({
            columns: asyncData.columns,
            rows: asyncData.rows,
        });

        function onAdd(newRow) { setAsyncData({ ...asyncData, rows: [...asyncData.rows, newRow] }) }
        function onModify(modifiedData) { console.log(modifiedData); setAsyncData({ ...asyncData, rows: modifiedData }) }
        function hapus(id) { hapusData(asyncData.rows.filter((row, i) => i === id)) }
        return (
            <>
                <MDBBtn className='mb-4' onClick={() => setAsyncData({ ...asyncData, rows: [] })}>
                    Reload data
                    <MDBIcon icon='sync' className='ms-2' />
                </MDBBtn>

                <MDBTableEditor striped
                    selectable
                    isLoading={loading}
                    mode='modal'
                    data={asyncData}
                    entriesOptions={[5, 10, 15]}
                    entries={5}
                    onAdd={(newRow) => setAsyncData({ ...asyncData, rows: [...asyncData.rows, newRow] })}
                    onModify={(modifiedData, i) => { console.log(modifiedData[i]); setAsyncData({ ...asyncData, rows: modifiedData }) }}
                    onDelete={(id) => { setAsyncData({ ...asyncData, rows: asyncData.rows.filter((row, i) => i !== id) }); hapusData(asyncData.rows[id]) }}
                />
            </>
        );
    }
    //{ setAsyncData({ ...asyncData, rows: modifiedData })};


    return (
        <>


            <MDBContainer >
                <h1 className='my-3 text-center'><Text tid={props.match.params.aksi && props.match.params.aksi} />  <Text tid="Survey" /> </h1>
             
           
                    {isform && (
                    <>
                        <MDBCard>
                            <MDBCardBody>
                        <form onSubmit={handleSubmit(onSubmit)} className="" d >
                            <Controller
                                name="judul"
                                control={control}
                                rules={{ required: true, minLength: 4 }}
                                render={({ field }) => <MDBInput className="mb-4" label="judul" {...field} />}
                            />
                            {errors.judul?.type === 'minLength' && "Judul harus diisi"}
                            <Controller
                                name="tanya"
                                control={control}
                                rules={{ required: true, minLength: 4 }}
                                        render={({ field }) => <MDBTextArea rows={4} className="mb-4" label="Pertanyaan" {...field} /> }
                            />
                            {errors.tanya?.type === 'required' && "Pertanyaan harus diisi"}

                             <Controller
                                        name="pilihan"
                                        control={control}
                                        render={({ field }) => <MDBInput className="mb-4" label="Pilihan (pisahkan dengan koma)" {...field} />}
                                    />

                            <Controller
                                name="urltwibbon"
                                control={control}
                                render={({ field }) => <MDBInput className="mb-4" label="Url Twibbon" {...field} />}
                            />
                                    <Controller
                                        name="urlartikel"
                                        control={control}
                                        render={({ field }) => <MDBInput className="mb-4" label="Url Artikel" {...field} />}
                                    />
                                    <Controller
                                        name="poin"
                                        control={control}
                                        render={({ field }) => <MDBInput className="mb-4" label="Poin" {...field} />}
                                    />
                                    {errors.poin?.type === 'required' && "Poin harus diisi"}

                    <MDBBtn color="primary" block type="submit"  > Simpan </MDBBtn>
                    {dataQuiz.length > 1 && <MDBBtn color="none" tag="a" block type="button" onClick={nextQuiz}  > Next Quiz </MDBBtn>}
                                </form> </MDBCardBody>
                            </MDBCard>
                        </>
                        
                )}

                { (!isform && dataQuiz.length>0  ) && <TabelKu data={dataQuiz } />}
                

               
            </MDBContainer>

        </>
    )




}