import React, { useState, useContext } from 'react';
import useToggle from './fungsi/useToggle';
import UserContext from './konteks/userContext'

// props : name, img
const Avatar = (props) => {
    const [error, onError] = useToggle()
    const src = props.img
    const username = props.name
  //  console.log (user)
    return (
        <>
            {error ? (
                <div>{username}</div>
            ) : (<img
                src={src}
                className="rounded-circle me-2"
                style={{ width: "30px", height: "30px" }}
                alt={username}
                onError={onError()}
            />
            )}
        </>
    )
}// JavaScript source code

export default Avatar;
