import React, { useState, useEffect, useRef } from "react";
import {
  MDBCard,
  MDBCardBody,
  MDBBtn,
  MDBIcon,
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
    MDBContainer, MDBScrollbar
} from "mdb-react-ui-kit";

import Avatar from "react-avatar"

export function ChatApp  () {
  const [basicOpen, setBasicOpen] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
    const [modeCollapse1, setModeCollapse1] = useState(true);
    const [modeCollapse2, setModeCollapse2] = useState(false);
    const [mode, setMode] = useState('side');
  const innerWidth = useRef(null);
    const [activeBtn, setActiveBtn] = useState('first');
    const sidenavContent = useRef(null);
    const [container, setContainer] = useState();


    useEffect(() => {
        setContainer(sidenavContent.current);
    }, []);

  useEffect(() => {
    const checkResize = () => {
      if (window.innerWidth === innerWidth.current) {
        return;
      }

      innerWidth.current = window.innerWidth;

      if (window.innerWidth < 1000) {
        setBasicOpen(false);
        setBackdrop(true);
      } else {
        setBasicOpen(true);
        setBackdrop(false);
      }
    };
    checkResize();

    window.addEventListener("resize", checkResize);

    return () => {
      window.removeEventListener("resize", checkResize);
    };
  }, []);
    const ListUser = () => {
        return (<React.Fragment>
            <MDBSideNavItem>
                <MDBSideNavLink>
                    <Avatar size="30" round={true} color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])} name="jane" ></Avatar>
            
                    <span className="px-1 d-block"> Anna Doe</span>
                </MDBSideNavLink>
            </MDBSideNavItem>

        </React.Fragment>)
    }
    const ListConvUser = (userid) => {
        return (<React.Fragment>
            <div className="d-flex justify-content-start w-100">
                <Avatar size="30" round={true} color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                    name="jane" ></Avatar>

                <p
                    className="p-3 bg-light  shadow-1 shadow-1"
                    style={{ maxWidth: "533px" }}
                >
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde
                    praesentium ad, dolores quaerat maxime architecto dolore,
                    necessitatibus rem possimus quam ratione sit neque quidem
                    officia dolorum nostrum enim dignissimos eum.
                    <small className="float-end text-dark mt-4">14:52</small>
                </p>
            </div>

        </React.Fragment>)
    }
    const ListConvBiz = (userid) => {
        return (<React.Fragment>
            <div className="d-flex justify-content-end ms-auto">
                <p
                    className="p-3 bg-primary shadow-1-strong text-white "
                    style={{ maxWidth: "533px" }}
                >
                    Lorem, ipsum dolor sit amet consectetur adipisicing elit. Unde
                    praesentium ad, dolores quaerat maxime architecto dolore,
                    necessitatibus rem possimus quam ratione sit neque quidem
                    officia dolorum nostrum enim dignissimos eum.
                    <small className="float-end mt-4">14:53</small>
                </p>
                <Avatar size="30" round={true} color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                    name="jane" ></Avatar>
    
            </div>
        </React.Fragment>)
    }
  return (
      <MDBContainer className="py-5">
          <div className='d-flex my-4'>
              <MDBBtn
                  onClick={() => {
                      setMode('push');
                      setActiveBtn('first');
                  }}
                  outline={activeBtn !== 'first'}
                  className='me-3'
              >
                  Push
              </MDBBtn>
              <MDBBtn
                  onClick={() => {
                      setMode('side');
                      setActiveBtn('second');
                  }}
                  outline={activeBtn !== 'second'}
                  className='me-3'
              >
                  Side
              </MDBBtn>
              <MDBBtn
                  onClick={() => {
                      setMode('over');
                      setActiveBtn('third');
                  }}
                  outline={activeBtn !== 'third'}
                  className='me-3'
              >
                  Over
              </MDBBtn>
          </div>
          <section >
        <MDBCard>
          <MDBCardBody style={{ position: "relative", overflow: "hidden" }}>
            <section className="inner-sidenav">
              <MDBSideNav
                              mode={mode}
                              contentRef={container}
                absolute
                isOpen={basicOpen}
                backdrop={backdrop}
                getOpenState={(e) => setBasicOpen(e)}
                style={{ zIndex: 1000 }}
              >
                <div className="p-3">
                  <input
                    type="search"
                    className="form-control rounded"
                    placeholder="Search"
                    aria-label="Search"
                    aria-describedby="search-addon"
                  />
                </div>
                              <MDBScrollbar sidenav tag='div' suppressScrollX style={{ height: '260px' }}>
              
                                  <ListUser></ListUser>
                                  <ListUser></ListUser>
                                  <ListUser></ListUser>
                                  <ListUser></ListUser>
                                  <ListUser></ListUser>
                                 </MDBScrollbar>
              </MDBSideNav>
                      </section>
          
            <section className="inner-content" ref={sidenavContent} >
                          <div style={{ padding: '20px' }} className='text-center'>
                              <MDBBtn onClick={() => setBasicOpen(!basicOpen)}>
                                  <MDBIcon fas icon='bars' />
                              </MDBBtn>
                          </div>
              <div className="d-flex">
                <MDBBtn
                  color="light"
                  className="shadow-0 p-0 me-3 d-block d-lg-none "
                  style={{ minWidth: "14px" }}
                  onClick={() => setBasicOpen(!basicOpen)}
                >
                  <MDBIcon fas icon="bars" size="lg" />
                </MDBBtn>
                              <Avatar size="30" round={true} color={Avatar.getRandomColor('sitebase', ['red', 'green', 'blue'])}
                                  name="jane" ></Avatar>

                              <span className="px-1 d-block" >Anna Doe</span>
              </div>

              <hr />
                          <ListConvUser></ListConvUser>
                          <ListConvUser></ListConvUser>
                          <ListConvBiz></ListConvBiz>
                          <ListConvUser></ListConvUser>
                          <ListConvUser></ListConvUser>
                          <ListConvBiz></ListConvBiz>
                          <ListConvUser></ListConvUser>
                          <ListConvUser></ListConvUser>
                          <ListConvBiz></ListConvBiz>
                          <ListConvUser></ListConvUser>
                          <ListConvUser></ListConvUser>
                          <ListConvBiz></ListConvBiz>
     
            </section>

            <section id="message-input" className="fixed-bottom card bg-light">
              <MDBCardBody>
                <form className="d-flex">
                  <MDBBtn noRipple className="px-3">
                    <i className="fas fa-plus-circle"></i>
                  </MDBBtn>

                  <div className="w-100 mx-2">
                    <textarea
                      className="form-control"
                      id="textAreaExample"
                      rows="1"
                      placeholder="Type a message"
                    ></textarea>
                  </div>

                  <button type="button" className="btn btn-primary px-3">
                    <MDBIcon fas icon="thumbs-up" />
                  </button>
                </form>
              </MDBCardBody>
            </section>
          </MDBCardBody>
        </MDBCard>
          </section>
         
    </MDBContainer>
  );
}


