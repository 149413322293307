import React, { useState, useContext, useEffect,useReducer } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBCardText, MDBCardBody, MDBCardHeader, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
import { Dialog }  from './Dialog'
import { Route } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { dataAksi } from "./data/datacontribute"
import reducer from "./konteks/reducer"

export function Contribute() {
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const { user, changeUser } = useContext(UserContext)
    const isAuth = user.islogedin;
    const [dataQuiz, setdataQuiz] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    let history = useHistory();
    const defaultValues = {
        jawaban: ""
    };
    const location = useLocation();
    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state

    const onClick = (url) => {
       
        history.push(url);

    }

    useEffect(() => {
      //  console.log(dataAksi)
        setdataQuiz(dataAksi);
    }, []);


    function SubJudul (props) {
        const a =props.data
      
        return (
            <>
                <MDBCard shadow='0' border={a.warna} background='white' className='mb-3 w-100 d-grid gap-1 col-6  h-100' >
                <MDBCardHeader>Poin : {a.poin} </MDBCardHeader>
                <MDBCardBody className='text-${a.warna} text-center'>
                        <div className=" mx-auto mb-2">
                          
                            <MDBBtn rounded disabled={!isAuth} color={a.warna} onClick={(e) => { e.preventDefault; onClick(a.url + "/contribute") }}  >{a.label}</MDBBtn>
                                            
                    </div>
                    <MDBCardText>
                           
                    </MDBCardText>
                </MDBCardBody>
                </MDBCard>
               
           </>
        );

    }




    return (
        <>
                  
            <MDBRow className='row-cols-1  row-cols-md-3 g-1'>
               
                {dataAksi.map((d, i) =>
                (<React.Fragment key={"contribute"+d.id}>
                    {d.visible &&
                       
                     
                            <MDBCol>
                            <SubJudul key={"subjudul" + i} data={d} indeks={i } />
                            </MDBCol>
                       
                    }
                    
                </React.Fragment>
                ))
            }

           </MDBRow>
          
            </>)
}


