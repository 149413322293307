import React, { Component, useState, useEffect,useContext, Fragment } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBIcon,MDBAlert, MDBListGroupItem,MDBListGroup,MDBCard, MDBCardBody, MDBCardHeader, MDBCardText, MDBBtn, MDBTextArea, MDBInput } from "mdb-react-ui-kit";
import { Dialog } from './Dialog.js'
import {Text } from './Text'
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext'
import { useHistory } from "react-router-dom";
import { QuoteList } from './QuoteList';

export function Quote(props) {

    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const [isform, setisform] = useState(false);

    const { user, changeUser } = useContext(UserContext)
    const isAuth = user.islogedin;
    const [hasData, sethasData] = useState(false)
    const [isloading, setIsLoading] = useState(false);
    const [haserror, setHasError] = useState(false);
    const [pesanerror, setPesanError] = useState('');
    const [dataQuote, setdataQuote] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [jawaban, setJawaban] = useState('');
    let history = useHistory();
    const defaultValues = {
        jawaban: "",
        nama:""
    };
    const param = props.match.params.aksi && props.match.params.aksi

    useEffect(() => {
        const param = props.match.params.aksi && props.match.params.aksi
        setisform(false);
        switch (param) {
            case "contribute":
                setisform(true);
                // code block
                break;
            case "list":
             
                // code block
                break;
            case "":
                break;
            default:
                console.log("");
            // code block
        }
    },[]);
    const Pesan = (props) => {
        const link = props.link
        const judul = props.judul
        return (
            <>

                <MDBBtn outline color="primary" className="mt-4" tag='a' href={link} >
                    {judul}
                </MDBBtn>
            </>
        )
    }
    const saveFormData = async (formValue) => {
        const formNet = {
            ...formValue, iddialog:3, "membernama" : user.nama ,  "memberid": user.id, "poin": 10, "like": 1
        }
        //   console.log(JSON.stringify(formValue));

        //   setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   })
        //  console.log(JSON.stringify(formValue));
        setIsLoading(true); //console.log(jawaban)
        const response = await fetch('https://ibw.news/simpancontribute', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            sethasData(false);
            setIsLoading(false);
            setHasError(true);
            throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        sethasData(true);
        setdataQuote(data);
        setJawaban(data.jawaban);
       // reset({ defaultValues });

        //changeUser({ ...data[0] });

    }
    function isOdd(num) { return (num % 2) == 1; }

    const onSubmit = async (data) => {

        try {
            //setSubmit(true);
            setHasError(false);
            await saveFormData(data);
            console.log(data)
            const sem = watch("jawaban");
         //   console.log(sem)
            setJawaban(sem);
            setIsLoading(false);
            setPesanError('Jawaban Sudah Disimpan');
          //  dataQuiz[quizIndex].isdone = true;
           // dataQuiz[quizIndex].jawaban = sem;
            setJawaban("");
            reset({ defaultValues })

        } catch (e) {
            setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            setIsLoading(false);
            setHasError(true);
          //  reset({ defaultValues })
        }
    }
    useEffect(() => {
        //const hasil = { "jawaban": "" }
       // saveFormData(hasil);
    }, [dataQuote]);

  
  
    return (
        
<>
            {isform &&
                  <MDBContainer>
                <MDBCard shadow='0' border="warning" background='white' className='mb-3 w-100' >
                    <MDBCardHeader>Masukkan Quote Kebanggaan  </MDBCardHeader>
                    <MDBCardBody >
                        <div className="d-grid gap-2 col-md-6   mx-auto mb-2">
                            <form onSubmit={handleSubmit(onSubmit)} className="my-4" >

                                <Controller
                                        name="jawaban"
                                        defaultValue=""
                                    control={control}
                                    rules={{ required: true, maxLength: 255 }}
                                        render={({ field }) => <MDBTextArea row={4 } className="mb-4" label="Jawaban" {...field} />}
                                />
                                    {errors.jawaban?.type === 'maxLength' && "Batas 255 karakter"}
                                    <Controller
                                        name="nama"
                                        defaultValue=""
                                        control={control}
                                        rules={{ required: true, maxLength: 255 }}
                                        render={({ field }) => <MDBInput  className="mb-4" label="Pengarang" {...field} />}
                                    />
                                    {errors.nama?.type === 'required' && "Tulis anonim jika tidak tahu pengarangnya"}
                                <MDBBtn type="submit"  rounded disabled={!isAuth}  ><Text tid="save"/> </MDBBtn>
                            </form>

                                {(haserror ) &&
                                    <>
                                        <MDBAlert show className='w-100' color='warning'>
                                            {pesanerror}
                                   </MDBAlert>
                                    </>
                                }

                        </div>
                        <MDBCardText>
                          
                        </MDBCardText>
                    </MDBCardBody>
                </MDBCard>

                </MDBContainer>} 

            <div className='my-3 text-center'>
   
                        
                <QuoteList data={dataQuote} adaJudul="false" />
        </div>
  </>
)


    
  
}






