import React, {
     useState, useContext,  useEffect
} from 'react';
import {
    MDBSelect,
    MDBContainer, MDBBtn, MDBIcon, MDBFooter,
    MDBLazyLoading, MDBInputGroup, MDBCheckbox, MDBInput,
    MDBNavbarLink,
    MDBCard,
    MDBCardBody,
    MDBCardHeader,


    MDBTextArea,
    MDBRow,
    MDBCol,
} from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext';
import { MDBFileUpload } from "mdb-react-file-upload";
import { useForm, Controller } from "react-hook-form";
import { Text } from './Text';
import { useMDBCookies } from 'mdb-react-cookies-management';

export function Profil (props)  {
    const { user, changeUser } = useContext(UserContext)
    const { verifwa, verifemail, adaanak } = user
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const { register:register2, control:control2, reset:reset2, handleSubmit:handleSubmit2, watch:watch2, formState: { errors:errors2 } } = useForm();
    const [fileku, setFileku]=useState()
    const onLogout = () => { changeUser({}) }
    const onLogin = () => {
        history.push("/login");
        return false
    }
    const [isloading, setIsLoading] = useState(false);
    const [dataProv, setdataProv] = useState([])
    const [hasData, sethasData] = useState(false)
    const [Loading, setLoading] = useState(true)
    const [CookieUser, { setItem: setCookieUser, removeItem: removeItemCookieUser }] = useMDBCookies('user')

    useEffect(() => {
        function populateData() {
            const response = fetch('getprovinsi').then(
                res => {
                    if (res.ok) return res.json();
                    throw res;
                }
            ).then(data => {
                setdataProv(data); sethasData(true); setLoading(false)
            }
            );

        }
       // populateData();
    }
        , []);



    const saveFormData = async (formValue) => {
        const formNet = {
            ...formValue, "memberid" : user.memberid,"picture" : formValue.picture[0] }
         
        let formData = new FormData();
        formData.append("Bytes",fileku[0]);
        formData.append("memberid", user.id);
        formData.append("ext", fileku[0].name.split('.')[1] )
       // console.log(fileku[0]);
        //   setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   })
        //  console.log(JSON.stringify(formValue));
      //  setIsLoading(true); //console.log(jawaban)
        const response = await fetch('https://ibw.news/simpanfoto', {
            method: 'POST', headers: {
                headers: { 'Content-Type': undefined }
            },
            body : formData
        });
        if (response.status !== 200) {
        //    setIsLoading(false);
        //    setHasError(true);
            throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        user.img = "https://ibw.news/img/profil/" + user.id + fileku[0].type.split('/')[1] 
        
        changeUser({ ...user });
        setCookieUser({ ...user });
      //  setJawaban(data.jawaban);

        //changeUser({ ...data[0] });

    }

    useEffect(() => {
        if (user) {
            setCookieUser(user)
        }
    }, [user])


    const onSubmit = async (data) => {

        try {
           // console.log("pusingggggg");
            //setSubmit(true);
            await saveFormData(data);
           // const sem = watch("jawaban");
          //  console.log(sem)
       //     setJawaban(sem);
        //    setIsLoading(false);
        //    setPesanError('Jawaban Sudah Disimpan');
        //    dataQuiz[quizIndex].isdone = true;
        //    dataQuiz[quizIndex].jawaban = sem;
        //    setJawaban("");
       //     reset({ defaultValues })

        } catch (e) {
        //    setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
        //    setIsLoading(false);
        //    setHasError(true);
        }
    }
    const saveFormData2 = async (formValue) => {
        const formNet = {
            ...formValue,  "memberid": user.id
        }
        setIsLoading(true);
        const response = await fetch('https://ibw.news/update', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            //  setHasError(true);
            setIsLoading(false);
            throw new Error(`Request failed: ${response.statusText}`);
        }
        const data = await response.json();
    }

    const onSubmit2 = async (data) => {

        try {
           // console.log("pusingggggg");
            //setSubmit(true);
            await saveFormData2(data);
            // const sem = watch("jawaban");
            //  console.log(sem)
            //     setJawaban(sem);
            //    setIsLoading(false);
            //    setPesanError('Jawaban Sudah Disimpan');
            //    dataQuiz[quizIndex].isdone = true;
            //    dataQuiz[quizIndex].jawaban = sem;
            //    setJawaban("");
            //     reset({ defaultValues })

        } catch (e) {
            //    setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            //    setIsLoading(false);
            //    setHasError(true);
        }
    }

    return (
        <>
            <MDBContainer className="pt-5 d-none">
                <h4 className=''> Data profil </h4>
                {user.id && <>

                    {verifwa ? (
                        <MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox defaultChecked disabled />}>
                            <MDBInput label='wa' className='form-control' value={user.wa} type='text' />
                        </MDBInputGroup>
                    ) : (<MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox />}>
                        <MDBInput label='email' className='form-control' value={user.wa} type='text' />
                    </MDBInputGroup>
                    )}
                    {verifemail ? (
                        <MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox defaultChecked disabled />}>
                            <MDBInput label='email' className='form-control' value={user.username} type='email' />
                        </MDBInputGroup>
                    ) : (<MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox />}>
                        <MDBInput label='email' className='form-control' value={user.username} type='email' />

                    </MDBInputGroup>
                    )}
                    <MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox />}>


                        {user.prov === undefined ? (<MDBSelect data={dataProv} search />) :
                            (<MDBInput label='Provinsi' disabled className='form-control' value={user.prov} type='text' />)}


                    </MDBInputGroup>

                    <MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox />}>
                        <MDBInput label='kota' className='form-control' value={user.kota} type='text' />
                    </MDBInputGroup>

                    <MDBInputGroup className='mb-3' textTag='div' textBefore={<MDBCheckbox />}>
                        <MDBInput label='kecamatan' className='form-control' value={user.kec} type='text' />
                    </MDBInputGroup>
                    <MDBBtn disabled> Simpan </MDBBtn>
                </>}


                {user.id === undefined ? (<MDBBtn onClick={onLogin}>Login </MDBBtn>) : (<>  <MDBBtn onClick={onLogout}>Logout </MDBBtn> </>)}


            </MDBContainer>

            <MDBContainer>
                <h1 className="h5 text-center py-5 mb-0">User profile</h1>
                <section>
                    <MDBRow>
                        <MDBCol md="8" className="mb-4 mb-md-0">
                            <MDBCard className="mb-4">
                                <MDBCardHeader className="card-header py-3">
                                    <strong>Edit profile</strong>
                                </MDBCardHeader>
                                <MDBCardBody className="text-center">
                                    <div className="mb-3">
                                        <strong>Profile photo</strong>
                                    </div>

                                    <form encType="multipart/form-data" onSubmit={handleSubmit(onSubmit)} className="my-4" > 
                                      

                                            <Controller
                                                name="picture"
                                                control={control}
                                                rules={{ required: true }}
                                                
                                            render={({ field }) => <MDBFileUpload defaultFile={user.img} style={{ height: '400px' }}
                                                maxFileSize="1M" getInputFiles={(files) => setFileku(files)} acceptedExtensions="image/*"  {...field} />}
                                        />

                                        <MDBBtn type="submit" block className="mb-4">
                                            <Text tid="save" />
                                        </MDBBtn>
                                    </form>


                                
                                </MDBCardBody>
                            </MDBCard>

                        </MDBCol>

                    </MDBRow>
                </section>
                <section>

                    <MDBRow>
                        <MDBCol md="8" className="mb-4 mb-md-0">
                            <MDBCard className="mb-4">
                                <MDBCardHeader className="card-header py-3">
                                    <strong></strong>
                                </MDBCardHeader>
                                <MDBCardBody className="text-center">
                                    <div className="mb-3">
                                        <strong>Data Diri</strong>
                                    </div>

                                    <form onSubmit={handleSubmit2(onSubmit2)} className="my-4" >

                                        <Controller
                                            name="nama"
                                            control={control2}
                                            rules={{ required: true, minLength: 3 }}
                                            render={({ field }) => <MDBInput defaultValue={user.nama} className="mb-4" label="Nama" {...field} />}
                                        />
                                        {errors2.nama?.type === 'minLength' && "Nama harus diisi"}
                                        <Controller
                                            name="email"
                                            control={control2}
                                            rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                                            render={({ field }) => <MDBInput defaultValue={user.email} type="email" className="mb-4" label="Email" {...field} />}
                                        />

                                        {errors2.email?.type === 'pattern' && "Masukkan email yang valid"}

                                        {errors2.nama?.type === 'minLength' && "Nama harus diisi"}

                                        <Controller
                                            name="wa"
                                            control={control2}
                                            rules={{ required: true, pattern: /^.*62.*$/ }}
                                            render={({ field }) => <MDBInput defaultValue={user.wa} type="number" className="mb-4" label="Whatsapp" {...field} />}
                                        />
                                        {errors2.wa?.type === 'pattern' && "Nomor Whatsapp harus diawali dengan 62"}

                                        <Controller
                                            name="gender"
                                            control={control2}
                                            rules={{ required: true }}
                                            render={({ field }) => <MDBInput defaultValue={user.gender === "p" ? "perempuan" : "laki-laki"} className="mb-4" label="Gender" {...field} />}
                                        />

                                        <Controller
                                            name="grup"
                                            control={control2}
                                            rules={{ required: true, minLength: 3 }}
                                            render={({ field }) => <MDBInput defaultValue={user.grup} className="mb-4" label="Grup" {...field} />}
                                        />


                                        <Controller
                                            name="poin"
                                            control={control2}
                                            rules={{ required: true, minLength: 3 }}
                                            render={({ field }) => <MDBInput
                                                className="mb-4"
                                                label="Poin" disabled
                                                defaultValue={user.poin}
                                             {...field} />}
                                        />
                           



                                        <MDBBtn type="submit"  disabled="disabled" block className="mb-4">
                                            <Text tid="save" />
                                        </MDBBtn>
                                    </form>



                                </MDBCardBody>
                            </MDBCard>

                        </MDBCol>

                    </MDBRow>
                    </section>
            </MDBContainer>
        </>
    )

}
