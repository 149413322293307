import React, { Component, useContext, useState, useEffect, useReducer, useRef, Fragment } from 'react';
import { MDBListGroupItem, MDBContainer,MDBCardImage, MDBBadge, MDBListGroup, MDBCardTitle, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBCardText, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Text } from './Text';
import { Spinner } from "./fungsi/Spinner";
import reducer from "./konteks/reducer";
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext';
import { useHistory, useLocation } from "react-router-dom";


export function ArtikelPerKategori(props) {
    const { user, changeUser } = useContext(UserContext)
    const param = props.kategori
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const [dataKu, setDataKu] = useState([]);
    const [dataSurveyKu, setDataSurveyKu] = useState([])
    const loadData = async (formValue) => {

        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/ambilartikel/'+param+'/' + formValue , {
            method: 'GET'
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataKu(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }
    const loadDataSurvey = async (formValue) => {

        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/turnsurvey/ambil/' + formValue, {
            method: 'GET'
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataSurveyKu(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }
    useEffect(
        () => {
            let timer1 = setTimeout(() => loadData("list"), 1000);
            let timer2 = setTimeout(() => loadDataSurvey(param), 1000);
           

            return () => {
                clearTimeout(timer1);
                clearTimeout(timer2);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );

    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })

    if (isLoading) return (
        <Spinner />
    )
    return (            <MDBContainer  className=" my-2">
                             <MDBRow className="row justify-content-center">
                                 <MDBCol className="col-lg-10 col-xl-7">
    
        <MDBTypography tag='h2' className="fw-bold  my-2 text-center">Parenting</MDBTypography>

                  {dataSurveyKu.map((d, i) => (
                    <>
                              
            <MDBRow className="row gx-lg-5 mb-5 align-items-center">
              <MDBCol className="col-md-6 mb-4 mb-md-0">
                            
                                <img
                                    src={"https://ibw.news/turnsurveygrafik/ambil/" + d.id}
                  className="w-100 shadow-4-strong rounded-4 mb-4" alt="" aria-controls="#picker-editor"/> </MDBCol>
                              <MDBCol className="col-md-6 mb-4 mb-md-0">
                                  <MDBTypography tag='h5' className="fw-bold  my-2 text-center">{d.judul}</MDBTypography>
                               
                <div className="mb-2 text-danger small"> <i className="fas fa-users me-2"
                                    aria-controls="#picker-editor"></i><span>{d.kategori}</span> </div>
                                <MDBListGroup style={{ minWidth: '22rem' }} light>
                                    {

                                        d.jawaban.map((a, x) => {
                                            return (


                                                <MDBListGroupItem key={'rekapjawaban' + a.surveypilihanid} className='d-flex justify-content-between align-items-center'>
                                                    {a.pilihan}
                                                    <MDBBadge>
                                                        {a.jawaban + " " + a.satuanukur}
                                                    </MDBBadge>
                                                </MDBListGroupItem>)
                                        })}
                                </MDBListGroup>

                                <a className="btn  my-2 btn-success" href={"https://wa.me/6285646555420?text=survey%20" + d.id } role="button" target="_blank"
                                      aria-controls="#isisurvey" rel="noreferrer"> Isi Survey</a>
              </MDBCol>
            </MDBRow>



                        </>










                    ))}
     


       

            {dataKu.map((d, i) => (
                <MDBRow className="row gx-lg-5 mb-5 flex-lg-row-reverse align-items-center" key={'artikel' + d.id}>
          <MDBCol className="col-md-6 mb-4 mb-md-0"> <img
                        src={d.imgheader}
                className="w-100 shadow-4-strong rounded-4 mb-4" alt="" /> </MDBCol>
            <MDBCol className="col-md-6 mb-4 mb-md-0">
                        <h3 className="fw-bold">{d.judul}</h3>
                <div className="mb-2 text-primary small"> <i className="fas fa-users me-2"
                    aria-controls="#picker-editor"></i><span>Adventure</span> </div>
                        {renderHTML(d.teaser)}
                        <MDBBtn tag="a" href={"https://ibw.news/artikel/" + d.judul} target="_blank" outline rounded onClick={() => window.open("https://ibw.news/artikel/" + d.judul, "_blank")}>
                            Baca
                        </MDBBtn>
            </MDBCol>
                </MDBRow>

                ))
            }
  
      
            </MDBCol>
        </MDBRow >
    </MDBContainer>





    );

}