

import React, { useState, useContext, useEffect } from 'react';
import UserContext from './konteks/userContext'
import {
    MDBContainer,
    MDBAlert
   
} from "mdb-react-ui-kit";
import { TodayQuiz } from './todayQuiz'

export function CarrouselHome() {
    const { user, changeUser } = useContext(UserContext)
    const [notAuth, setNotAuth] = useState(!user.islogedin)
    useEffect(() => setNotAuth(!user.islogedin), [user]);

    return (<div className='text-center'>
        
            <TodayQuiz/>

        {notAuth && <>
            <img src='https://ibw.news/img/comBattle.jpg' className='img-fluid shadow-4'
                alt='...'
            /></>
        }
            </div>
        );
    }