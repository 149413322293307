



import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import './custom.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import 'mdb-react-file-upload/dist/css/file-upload.min.css';
import 'mdb-react-table-editor/dist/css/table-editor.min.css';
import 'mdb-react-wysiwyg/dist/css/wysiwyg.min.css';
import 'mdb-react-multi-carousel/dist/css/multi-carousel.min.css';
import AppNew from './AppNew';
import reportWebVitals from './reportWebVitals';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <BrowserRouter basename={baseUrl}>
            <AppNew />
        </BrowserRouter>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
