import React, {
    Component, useState, useContext, useRef, useImperativeHandle, useEffect, useResource,
    forwardRef
} from 'react';
import { BrowserRouter, Route, Switch } from 'react-router';
import { Helmet } from "react-helmet";
import UserContext from './components/konteks/userContext';
import MenuContext from './components/konteks/menuContext';
import './custom.css';
import {
    MDBTabs, MDBTabsItem, MDBTabsLink,
    MDBSelect, MDBTooltip,
    MDBContainer, MDBBtn,MDBIcon,MDBFooter,
    MDBLazyLoading, MDBInputGroup, MDBCheckbox,MDBInput,
    MDBNavbarLink
} from 'mdb-react-ui-kit';
import {
 
    MDBCard,
    MDBCardBody,
    MDBCardHeader,

  
    MDBTextArea,
    MDBRow,
    MDBCol,
} from "mdb-react-ui-kit";

import { NavMenuMDB } from './components/NavMenuMDB';
import { useHistory } from "react-router-dom";
import { Home } from './components/Home';
import { Quote } from './components/Quote';
import { Survey } from './components/Survey';
import { Admin } from './components/Admin';
import { Parent } from './components/Parent';
import { QuoteList } from './components/QuoteListAdaJudul';
import { GrupRekap } from './components/GrupRekap';
import { FetchDataProvinsi } from './components/FetchDataProvinsi';
import { Profil} from './components/Profil';
import { Contribute } from './components/Contribute'
import { PeragaAnakRingkas } from './components/PeragaAnakRingkas'
import { CarrouselHome } from './components/carrouselHome'
import { TodayQuiz } from './components/todayQuiz'
import { Story } from './components/Story'
import { ArtikelList } from './components/ArtikelList'
import { Dashboard } from './components/Dashboard';
import Login  from './components/Login';
import { useMDBCookies } from 'mdb-react-cookies-management';
import { LanguageContext } from './components/konteks/LanguageContext';
import { LanguageProvider } from './components/konteks/LanguageProvider';
import { Text } from './components/Text';
import { Quiz } from "./components/Quiz"
import { Kepribadian } from "./components/Kepribadian"
import { Artikel } from "./components/Artikel"
import { Iklan } from './components/Iklan';
import { ChatApp } from './components/ChatApp2';

function AppNew() {
    let history = useHistory();
    const [activeMenu, setactiveMenu] = useState('')
    const [CookieUser, { setItem:setCookieUser, removeItem:removeItemCookieUser }] = useMDBCookies('user' )

    const [user, setUser] = useState([]);
    const [authenticated, setAuthenticated] = useState(false)
   // const { user,changeUser } = useContext(UserContext)
    const [iconsActive, setIconsActive] = useState('pill1');

    const handleIconsClick = (value: string) => {
        if (value === iconsActive) {
            return;
        }

        setIconsActive(value);
    };
    function changeUser(newUser) {
        setUser(newUser);
      //  changeUserkonteks(newUser);
       
    }


   const HeaderLoading =()=> {
        return (
            <React.Fragment>
            
                <MDBLazyLoading
                    lazySrc='https://ibw.news/img/ibwnews_cover.png'
                    lazyPlaceholder='https://place-hold.it/1321x583?text=Berita%20Baik%20untuk%20Jiwa'
      
                    lazyError='https://ibw.news/img/ibwnews_cover.png'
                    lazyDelay={1000}
                    alt='Berita Baik Untuk Jiwa'
                    className='img-fluid'
                    animation='slide-down'
                />
     
              
               <MDBNavbarLink active aria-current='page' href='#'>
                    Hai, {user.nama} 
                  
            </MDBNavbarLink>
                </React.Fragment>
)

    }
    const HeaderNew = (props) => {
        const { user, changeUser } = useContext(UserContext)
        const username = user.username
  
        const onLogout = () => { changeUser({}); removeItemCookieUser() }
        const onLogin = () => {
            history.push("/login");
            return false
        }
        const [authenticated, setAuthenticated] = useState(false)

        useEffect(() => setAuthenticated(user.islogedin), [user]);
      //  useEffect(() => {
         // console.log(CookieUser);
       //    console.log(user);
         //   setAuthenticated(user.islogedin);
         //   console.log(user.nama)
     //  }, []);

     
        return (
            <React.Fragment>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta name="description" content="Good News for Family" />
                    <meta name="og:title" content="Home" />
                    <meta name="og:description" content="Good News for Family" />
                    <link rel="canonical" href="http://ibw.news" />
                </Helmet>
                <MDBContainer>
                <div
                    className='p-5 text-center bg-image'
                    style={{ backgroundImage: "url('https://ibw.news/img/ibwnews_cover.png')", height: 200 }}
                >
                    <div className='mask' style={{ backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                        <div className='d-flex justify-content-center align-items-center h-100'>
                            <div className='text-white'>
                                <h1 className='mb-3'><Text tid="header"/> </h1>
                                    <h4 className='mb-3'>#mencerdaskan #keluargaKompak</h4>
                                    <MDBBtn tag="a" href="/today" outline color="white"  >
                                        <Text tid="headeraksi" /> 
                                    
                                    </MDBBtn>
                            </div>
                        </div>
                    </div>
                </div>


                    <MDBContainer className='my-2  align-items-end flex-column w-100'>
                        {authenticated ? (<> Hai, {user.nama} <MDBBtn tag="a" className="" outline rounded onClick={onLogout}>Logout </MDBBtn> </>) : (<> <MDBBtn tag="a" className="" outline rounded onClick={onLogin}>Login </MDBBtn> </>)  }

                    </MDBContainer>
                    </MDBContainer>
            </React.Fragment>
        )

    }
    const dataMenu = [
        {
            id: 1,
            label: 'kids',
            warna:'success',
            icon:'baby',
            url: '/kids',
            active: false,
            visible: false,
            ispublic: true
            
        }, {
            id: 6,
            label: 'parenting',
            warna: 'warning',
            icon: 'hands-helping',
            url: '/parent',
            active: false,
            visible: true,
            ispublic: true
        }, {
            id: 2,
            label: 'Social Impact',
            warna: 'primary',
            icon: 'globe-asia',
            url: '/quiz',
            active: false,
            visible: true,
            ispublic: true
        }, {
            id: 3,
            label: 'Pendidikan',
            warna: 'secondary',
            icon: 'user-graduate',
            url: '/pendidikan',
            active: false,
            visible: true,
            ispublic: true
        }, {
            id: 4,
            label: 'Kesehatan',
            warna: 'warning',
            icon: 'heartbeat',
            url: '/life',
            active: false,
            visible: true,
            ispublic: true
        }, {
            id: 5,
            label: 'profil',
            warna: 'light',
            icon: 'user',
            url: '/profil',
            active: false,
            restricted: false,
            visible: true,
            ispublic: true
        },
        {
            id: 7,
            label: 'admin',
            warna: 'light',
            icon: 'tools',
            url: '/admin',
            active: false,
            restricted: false,
            visible: false,
            ispublic:false
        }
    ]

    const HeaderMenu2 = (props) => {
    const dataku = dataMenu; 

        function onAuth(statusrestricted) {
            return authenticated ? false : (statusrestricted ? true : false)
        }
     
       // console.log(props.activeMenu);
        return (
            <>
                <MDBContainer className='d-flex justify-content-center my-2 '>
                    {dataku.map((d, i) =>
                    (<React.Fragment key={"headermenulg"+d.id}>
                        {(d.visible && d.ispublic) && 
                            <>
                        <MDBBtn color={props.activeMenu === d.label ? 'black' : d.warna} className=' mx-2 d-none d-lg-block' disabled={onAuth(d.restricted)}  onClick={() => props.onClick(d.label)}  >
                        <MDBIcon className='m-2' icon={d.icon} />
                        {d.label}
                 </MDBBtn>
                        <MDBBtn className='m-1 d-lg-none' color={props.activeMenu === d.label ? 'black' : d.warna}  menu={d.label} onClick={()=>props.onClick(d.label) }  >
                               
                               
                          <MDBIcon fas icon={d.icon}  />
                          
                                  

                            </MDBBtn>
</>
                        }
                        {(!d.ispublic && user.isadmin) &&
                            <>
                            <MDBBtn color={props.activeMenu === d.label ? 'black' : d.warna} className=' mx-2 d-none d-lg-block' disabled={onAuth(d.restricted)} onClick={() => props.onClick(d.label)}  >
                                <MDBIcon className='m-2' icon={d.icon} />
                                {d.label}
                            </MDBBtn>
                            <MDBBtn className='m-1 d-lg-none' color={props.activeMenu === d.label ? 'black' : d.warna} menu={d.label} onClick={() => props.onClick(d.label)}  >


                                <MDBIcon fas icon={d.icon} />



                            </MDBBtn>
                            </>

                        }
                       
             </React.Fragment>
                    )
)
                }
              </MDBContainer>
                </>
            
            )

    }






    const HeaderMenu = (props) => {
        const dataku = dataMenu; 
        function onAuth(statusrestricted) {
            return authenticated ? false : (statusrestricted ? true : false)
        }
        return (
            <MDBContainer className='d-flex justify-content-center my-2 '>
            <MDBTabs pills className='mb-3'>
                {dataku.map((d, i) =>
                (
                    
                    <React.Fragment key={"headermenulg" + d.id}>
                {                 
                    (d.visible && d.ispublic) && 
                        <MDBTabsItem>
                                    <MDBTabsLink onClick={() => props.onClick(d.label)} active={iconsActive === d.label}>
                                        <MDBIcon fas icon={d.icon} className='me-2' /> {d.label}
                            </MDBTabsLink>
                        </MDBTabsItem>

                            }
                   {(!d.ispublic && user.isadmin) &&
                   <>
                    <MDBTabsItem>
                                <MDBTabsLink disabled={onAuth(d.restricted)} onClick={() => props.onClick(d.label)} active={iconsActive === d.label}>
                                    <MDBIcon fas icon='chart-line' className='me-2' /> {d.label}
                        </MDBTabsLink>
                    </MDBTabsItem>
                       </>
                    }
                    </React.Fragment>
                ))
                      }
                </MDBTabs>
            </MDBContainer>
            )
 

    }






    const FooterKu = ()=>{

        return (<>
            <MDBFooter className='text-center  mt-3' bgColor=''  >
                                                    

                <div className='text-center p-2 m-2 text-white' style={{ backgroundColor: '#E65100' }}>
                    � 2022 Copyright :
                    <a className='text-white mx-2' href='https://ibw.news/'>
                        https://ibw.news/
                    </a>
                </div>
            </MDBFooter>
            </>)

    }
    function onClick(menu) {
      //  setactiveMenu(menu);
        history.push("/" + menu);
        if (menu === iconsActive) {
            return;
        }

        setIconsActive(menu);
        return false
    }
    // { match, location, history }
    const QuoteComp = ({ match }) => (
        <div>
            <Route path={match.url + "/:aksi"} component={Quote} >
              
             </Route>
        </div>
    );
    // { match, location, history }
    const ParentComp = ({ match }) => (
        <div>
            <Route  path={match.url + "/:aksi" + "/:id?"} component={Survey} >

            </Route>
        </div>
    );
    const ArtikelComp = ({ match }) => (
        <div>
            <Route path={match.url  + "/:id?/:halaman?"} component={Story} />

        </div>
    );
   // console.log(user);
    useEffect(() => {
        if (CookieUser) {
            changeUser(JSON.parse(CookieUser))
        }
    }, [])

  
        //   console.log("state");
  
        //  console.log("cookie");
   // console.log(JSON.parse(CookieUser).nama);
  //  if (user == []) { setUser(CookieUser); console.log(user); }
        //  setUser(CookieUser);
   //     if (CookieUser.id ) {
           // console.log(JSON.parse(CookieUser))
    //        changeUser(JSON.parse(CookieUser))
            //    console.log(CookieUser)
    //   }


        //  setAuthenticated(userkonteks.islogedin);

   // }, []);
     return (
        <>
            <LanguageProvider>
                 <UserContext.Provider value={{ user, changeUser }}>
                    
              <NavMenuMDB />
                     <Route exact path='/' component={HeaderNew} />  
                {activeMenu != "login" ||''&& <Route exact path='/login' component={HeaderNew} />}  
                <HeaderMenu activeMenu={activeMenu} onClick={onClick} />
                     <MDBContainer>

                         <Route exact path='/' component={Home} />
                         <Route exact path='/' component={QuoteList} />
                         <Route exact path='/' component={Iklan} />
                         <Route exact path='/' component={ArtikelList} />

             


                        <Switch> 
                             <Route exact path='/life' component={Dashboard} />
                             <Route exact path='/kids' component={PeragaAnakRingkas} />
                             <Route exact path='/parenting' component={Parent} />
                             <Route path='/balance' component={ParentComp} />
                       
                             <Route exact path='/quiz' component={CarrouselHome} />
                             <Route exact path='/contribute' component={Contribute} />
                             <Route path="/artikel" component={ArtikelComp} />
                             <Route  path='/admin' component={Admin} />
                       </Switch>

                   
                  
                 
                         <Route path="/quote" component={QuoteComp} />
                       
                     
                     <Route exact path='/today' component={TodayQuiz} />
                     {!user.islogedin && <Route exact path='/login' component={Login} /> } 

                                     {user.islogedin && <Route exact path='/profil' component={Profil} />} 

                


                     </MDBContainer>
                     <Route path="/chatbot" component={ChatApp} />
            </UserContext.Provider>
                <FooterKu />
                </LanguageProvider>
            </>
        
        )
}
export default AppNew;

