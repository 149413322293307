/* eslint-disable react/jsx-key */
/* eslint-disable react/no-unknown-property */
import React, { Component, useContext, useState, useEffect, useReducer, useRef } from 'react';
import { MDBContainer, MDBPagination, MDBPaginationItem, MDBPaginationLink, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTextArea, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Text } from './Text';
import { Spinner } from "./fungsi/Spinner";
import reducer from "./konteks/reducer";
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext';
import { useHistory, useLocation } from "react-router-dom";
import { Iklan } from './Iklan';
import { TikTok } from "react-tiktok";
import useToggle from './fungsi/useToggle';
import { ArtikelDetail } from './ArtikelDetail';

export function ArtikelHalaman(props) {

    const { user, changeUser } = useContext(UserContext)
    const param = props.artikelid && props.artikelid 
    const halaman = props.halaman && props.halaman
    const [error, onError] = useToggle()
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const [dataKu, setDataKu] = useState([])
   // const [hasData, sethasData] = useState(true)
    const loadData = async (formValue) => {
   
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/turnambilartikelhalaman/'+ formValue.artikelid, {
            method: 'GET'
        });
        if (response.status !== 200) {
           // sethasData(false)
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
             throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataKu(data);
        dispatch({ type: "HAS_DATA" })
        dispatch({ type: "FINISH_LOADING" })
       //changeUser({ ...data[0] });

    }
    useEffect(
        () => {
            let timer1 = setTimeout(() =>loadData(props), 1000);

           
            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );
    const onClick = (url) => {

        history.push(url);
        return false;
    }
    const renderHTML = (rawHTML: string) => React.createElement("div", { dangerouslySetInnerHTML: { __html: rawHTML } })

    if (isLoading) return (
        <Spinner />
    )
    return (<>
    <nav aria-label='...' className=" my-2" >
            <MDBPagination className='mb-0' end >
   Halaman 

                {error ? (
                    <span onError={onError()}></span>
                ) : (dataKu.map((d, i) => (
                    ((parseInt(d) == props.halaman) ? (<MDBPaginationItem key={'halaman' + i} active aria-current='page' onError={onError()}>
                        <MDBPaginationLink href='#'>
                            {d} <span className='visually-hidden'>(current)</span>
                        </MDBPaginationLink>
                    </MDBPaginationItem>) : (<MDBPaginationItem >
                            <MDBPaginationLink key={'halaman' + i} href={"/artikel/" + props.judul + "/" + d} >
                            {d}
                        </MDBPaginationLink>
                    </MDBPaginationItem>)



                    ))))}

            </MDBPagination>
        </nav>
        {error ? (
            <span onError={onError()}></span>
        ) : (dataKu.map((d, i) => (
         
            <div key={"artikeldetailhalaman" + d} onError={onError()} className=" mt-1 mb-2">
                {d == halaman && <ArtikelDetail artikelid={param} halaman={d} ></ArtikelDetail>}
                </div>
           

        ))


        )

        }

         <nav aria-label='...'>
            <MDBPagination className='mb-0' end >
Halaman

                {error ? (
                    <p onError={onError()}></p>
                ) : (dataKu.map((d, i) => (
                    ((parseInt(d) == props.halaman) ? (<MDBPaginationItem active aria-current='page' onError={onError()}>
                            <MDBPaginationLink href='#'>
                                {d} <span className='visually-hidden'>(current)</span>
                            </MDBPaginationLink>
                        </MDBPaginationItem>) : (<MDBPaginationItem >
                            <MDBPaginationLink href={"/artikel/"+props.judul+"/"+d} >
                                {d}
                            </MDBPaginationLink>
                        </MDBPaginationItem>)
                        


                ))))}

            </MDBPagination>
        </nav>


       
        </>
  




    );

}