export const dataAksi = [
  {
    "id": 1,
    "label": "Saya punya pertanyaan / tebakan ",
    "warna": "success",
    "icon": "question",
    "url": "/tanya",
    "active": false,
    "visible": true,
    "poin": 10,
    "aksi": []

  },
  {
    "id": 2,

    "label": "Curhat",
    "warna": "primary",
    "icon": "tasks",
    "url": "/curhat",
    "active": false,
    "visible": false,
    "poin": -100,
    "aksi": []
  },
  {
    "id": 3,
    "label": "Bagikan Quote / Kata Mutiara",
    "warna": "secondary",
    "icon": "hand-holding-heart",
    "url": "/quote",
    "active": false,
    "visible": true,
    "poin": 10,
    "aksi": []
  },
  {
    "id": 4,
    "label": "Info kan Event",
    "warna": "warning",
    "icon": "calendar-alt",
    "url": "/event",
    "active": false,
    "visible": false,
    "poin": 10,
    "aksi": []
  },
  {
    "id": 5,
    "label": "Bagi Resensi Buku",
    "warna": "primary",
    "icon": "book-open",
    "url": "/buku",
    "active": false,
    "restricted": true,
    "visible": false,
    "poin": 10,
    "aksi": []
  },
  {
    "id": 6,
    "label": "Terjemahkan",
    "warna": "success",
    "icon": "language",
    "url": "/terjemahan",
    "active": false,
    "restricted": true,
    "visible": false,
    "poin": 10,
    "aksi": []
  }
] 

export default {
  dataAksi,
}
