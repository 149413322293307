import React, { useContext} from 'react';


export default function reducer(state, action) {


    switch (action.type) {

        case "START_LOADING":
            return { ...state, isLoading: true, pesanError: "", hasError: false }
        case "HAS_ERROR":
            return { ...state, hasError: true, hasData:false, pesanError: action.payload, isLoading: false, warnaPesan: "warning" }
        case "HAS_PESAN":
            return { ...state, hasError: true, pesanError: action.payload, isLoading: false ,warnaPesan : "success"}
        case "HAS_DATA":
           // console.log('update Has_data');
            return { ...state, hasData: true, isLoading: false ,hasError: false }
        case "FINISH_LOADING":
            return { ...state, hasError: false, pesanError: "", isLoading: false}
        case "SET_LOGIN":
            return { ...state, isLogin: action.payload, notAuth: !action.payload, hasError: false, isLoading: false }
        case "SET_DATA":
         //   console.log('update set_data');
            return { ...state, data: action.payload, hasData: true, hasError: false, isLoading: false }

        case "SET_POIN":
            //   console.log('update set_data');
            return { ...state, poin: action.payload, hasData: true, hasError: false, isLoading: false }
        case "TOGGLE_DETAIL":
            return { ...state, hasDetail: action.payload }

        default: return state;

    }
}