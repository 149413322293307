import React, { Component, useState, useEffect, useContext, Fragment, useReducer } from 'react';
import { MDBAlert, MDBContainer, MDBRow, MDBCol, MDBIcon, MDBListGroupItem, MDBListGroup, MDBCard, MDBTypography, MDBCardBody, MDBCardHeader, MDBCardText, MDBBtn, MDBTextArea, MDBInput } from "mdb-react-ui-kit";
import { Dialog } from './Dialog.js'
import {Text } from './Text'
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext'
import { useHistory } from "react-router-dom";
import { Spinner } from "./fungsi/Spinner"
import reducer from "./konteks/reducer"
export function QuoteList(props) {
    const [adaJudul, setAdaJudul] = useState(props.adaJudul ? props.adaJudul : true);
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();

    const { user, changeUser } = useContext(UserContext)
 //   const isAuth = user.islogedin;
  //  const [hasData, sethasData] = useState(false)
  //  const [isloading, setIsLoading] = useState(false);
  //  const [haserror, setHasError] = useState(false);
 //   const [pesanerror, setPesanError] = useState('');
    const [dataQuote, setdataQuote] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [jawaban, setJawaban] = useState('');
    let history = useHistory();
    const defaultValues = {
        jawaban: ""
    };
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail ,warnaPesan } = state
    

    const saveFormData = async (formValue) => {
        const formNet = {
            ...formValue, iddialog:3,"nama" : user.nama, "memberid": user.id, "poin": 10, "like": 1
        }
        //   console.log(JSON.stringify(formValue));

        //   setFormValue({
        //       iddialog: dataQuiz[quizIndex].iddialog, "jawaban": watch("jawaban"), "memberid": user.id, "poin": dataQuiz[quizIndex].poin, "like": dataQuiz[quizIndex].like
        //   })
        //  console.log(JSON.stringify(formValue));
        dispatch({type:"START_LOADING"})
        const response = await fetch('https://ibw.news/simpancontribute', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "HAS_ERROR", payload: "ada error : " + response.status })
            throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        dispatch({type:"HAS_DATA"})
       // sethasData(true);
        setdataQuote(data);
        setJawaban(data.jawaban);
      //  setIsLoading(false);

        //changeUser({ ...data[0] });

    }
    function isOdd(num) { return (num % 2) == 1; }

    const onSubmit = async (data) => {

        try {
            //setSubmit(true);
            await saveFormData(data);
            //console.log(data)
            const sem = watch("jawaban");
          //  console.log(sem)
            setJawaban(sem);
            
            dispatch({ type: "FINISH_LOADING" });
            dispatch({ type: "HAS_PESAN", payload: 'Jawaban Sudah Disimpan' })
           // setPesanError('Jawaban Sudah Disimpan');
          //  dataQuiz[quizIndex].isdone = true;
           // dataQuiz[quizIndex].jawaban = sem;
            setJawaban("");
            reset({ defaultValues })

        } catch (e) {
           // setPesanError(`Gagal Jawab ${e.message}`);
            // alert(`Login failed! ${e.message}`);
            dispatch({ type: "FINISH_LOADING" });
            dispatch({ type: "HAS_ERROR", payload: `Gagal Jawab ${e.message}` })
         //   setIsLoading(false);
         //   setHasError(true);
        }
    }
    useEffect(() => {
     
        if (props.data) {
           
        if (props && props.data.length > 0) {
            setdataQuote(props.data);

            }
        else {
            const hasil = { "jawaban": "" }
            saveFormData(hasil);
        }
        }
        else {
            const hasil = { "jawaban": "" }
            saveFormData(hasil);
        }  
  
    }, []);//props.data

    const DetilQuote = (props) => {
        const d = props.data
        const i=props.index
        const isKiri = isOdd(i);
        const kelas = isKiri ? "d-flex justify-content-start w-100" : "d-flex justify-content-end ms-auto";
      
        return (
               
        <Fragment key={"quotelistfragment"+i}>
                                

                <div className='my-3' >
                    <MDBCard >
                        <MDBCardHeader>               <div className='d-flex w-100 justify-content-between'>
                            <small>    <MDBBtn tag="a" outline color='none' >
                                {d.membernama} :
                            </MDBBtn> </small>

                            <small><MDBIcon far icon="thumbs-up" /></small>
                        </div></MDBCardHeader>

                        <MDBCardBody>
                            <MDBTypography blockquote className='mb-0'>
                                <p>    {d.jawaban}</p>
                                <footer className='blockquote-footer'>
                                     <cite title='Source Title'>{d.nama}</cite>
                                </footer>
                            </MDBTypography>
                        </MDBCardBody>
                    </MDBCard>

              
            </div>

        </Fragment>
            )

    }
    if (isLoading) return (
        <Spinner />
    )

    return (
        
        <div className='my-3 text-center'>



            {hasData &&  dataQuote.map((d, i) => {
                return (<>
                    <MDBListGroup key={"quotelist" + d.id} flush >
                        <DetilQuote data={d} index={i} />
                    </MDBListGroup>
                </>
                )
            })}
     


            </div>
)


    
  
}






