export const dataAksi = [
    {
        "judul": "quiz",
        "label": "Quiz atau Challenge ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false,
            "url":"/admin/quiz/pilih"
        }, {
                "id": 1,
                "label": "Entri",
                "active": false,
            "visible": false,
            "url": "/admin/quiz/entri"
            }
            ]
    },
    {
        "judul":"survey",
        "label": "Survey Kasus ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false,
            "url": "/admin/kepribadian/pilih"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "/admin/kepribadian/entri"
        }
        ]
    },
    {
        "judul": "artikel",
        "label": "Artikel  ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "/admin/artikel/pilih"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "/admin/artikel/entri"
        }
        ]
    },
    {
        "judul":"target",
        "label": "Challenge Setting Target ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihtarget"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entritarget"
        }
        ]
    },
    {
        "judul": "anak",
        "label": "Challenge Multiple Intelligence",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihintelegence"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entriintelegence"
        }
        ]
    },
    {
        "judul": "harta",
        "label": "Challenge keseimbangan harta ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihharta"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entriharta"
        }
        ]
    },
    {
        "judul": "hadiah",
        "label": "Entri Hadiah Poin ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihhadiah"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entrihadiah"
        }
        ]
    },
    {
        "judul":"admin",
        "label": "Pilih Admin ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihadmin"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entriadmin"
        }
        ]
    },
    {
        "judul": "member",
        "label": "Dashboard Member ",
        "aksi": [{
            "id": 1,
            "label": "Lihat",
            "active": false,
            "visible": false, "url": "lihatmember"
        }
        ]
    },
 
    {
        "judul": "twibbon",
        "label": "Desain Twibbon ",
        "aksi": [{
            "id": 1,
            "label": "Pilih",
            "active": false,
            "visible": false, "url": "pilihtwibbon"
        }, {
            "id": 1,
            "label": "Entri",
            "active": false,
            "visible": false, "url": "entritwibbon"
        }
        ]
    } 


] 

export default {
  dataAksi
}
