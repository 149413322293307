import React, { useContext} from 'react';
import { MDBChart, MDBListGroup, MDBListGroupItem, MDBCardBody, MDBCard, MDBCardHeader } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
export function Dashboard() {
    const { user, changeUser } = useContext(UserContext)
    const nama= 'Harta '+user.nama
    return (
        <MDBCard className='mx-2'>

            <MDBCardHeader>6 JENIS HARTA KEHIDUPAN </MDBCardHeader>
         
                <MDBListGroup flush>
                    <MDBListGroupItem>Harta Rohani ( Spiritual Asset )</MDBListGroupItem>
                    <MDBListGroupItem>Harta Jiwani ( Soul Asset )</MDBListGroupItem>
                    <MDBListGroupItem>Harta Tubuh ( Body Asset )</MDBListGroupItem>
                    <MDBListGroupItem>Harta Hubungan ( Network Asset )</MDBListGroupItem>
                    <MDBListGroupItem>Harta Keluarga ( Family Asset )</MDBListGroupItem>
                    <MDBListGroupItem>Harta Benda ( Financial Asset )</MDBListGroupItem>
                </MDBListGroup>
            <MDBCardBody className='d-flex justify-content-center'>
                <MDBChart
                    type='radar'
                    data={{
                        labels: ['Rohani', 'Jiwani', 'Tubuh', 'Hubungan', 'Keluarga', 'Keluarga', 'Materi'],
                        datasets: [
                            {
                                label: nama ,
                                data: [987, 2343, 2545, 3423, 2365, 1985, 2112 ],
                               
                            },
                        ],
                    }}
                />
     
            </MDBCardBody >
            </MDBCard>
  );
}



