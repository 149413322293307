import React, { Component, useState, useEffect } from 'react';
import { MDBContainer, MDBTypography, MDBCardGroup, MDBCardHeader, MDBAnimation,MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBMask, MDBIcon, MDBView, MDBBtn } from "mdb-react-ui-kit";
import { Dialog } from './Dialog.js'
import { Text } from './Text'
import { Survey } from './Survey';
import { TodayQuiz } from './todayQuizAdaJudul'

export function Home(props) {
    const [isform, setisform] = useState(false);
    const [kata, setkata] = useState("kelas");
    const [warna, setwarna] = useState("text-warning");
    const listkata = ["kelas", "curhat", "kuis", "game", "survey", "promo", "poverty", "gender", "peace", "cleanwater", "education", "hunger"]
    const listkata2 = ["poverty", "gender", "peace", "cleanwater", "education", "hunger"]
    const listwarna = ["bg-warning", "bg-dark", "bg-success", "bg-secondary", "bg-info", "bg-danger", "bg-warning", "bg-dark", "bg-success", "bg-secondary", "bg-info", "bg-danger"]
    const param = props.match.params.aksi && props.match.params.aksi
    let p;
    let cursor;
    cursor = 0;
    p = 0;
    switch (param) {
        case "contribute":
            setisform(true);
            // code block
            break;
        case "list":
            // code block
            break;
        case "":
            break;
        default:
         //   console.log("");
        // code block
    }
    useEffect(() => {
        //  console.log(dataAksi)

        setInterval(function () {
            p++
            if (Math.trunc(p / 10)-(p/10) == 0) {
                cursor++
                setkata(listkata[cursor]);
                setwarna(listwarna[cursor]+ " text-white px-2 py-1 mx-0");
                
                if ((cursor+1) == listkata.length) cursor = 0;
            }
           
        }, 500);
    }, []);

    return (
<>


        <div className='my-3 text-center'>
             
                <MDBTypography tag='h1' className='mt-3'>
                    <MDBIcon fab icon="whatsapp" className="text-success" /> <a target="_blank" href={"https://wa.me/6285646555420?text=" + kata} rel="noreferrer"> wa.me/6285 646 555 420<span className="text-muted"> ? text=</span></a>
                    <MDBAnimation
                    infinite
                    tag='span'
                        animation='pulse'
                        className= {warna}
                    start='onLoad'
                    duration={1500}
                    >{ kata }</MDBAnimation>
                              
                </MDBTypography>
                <hr/>
                <p className="text-warning"> <Text tid="maininfo" /> </p>
                <hr />
                {props.match.params.aksi && props.match.params.aksi}
                <TodayQuiz />
            </div>
                <MDBRow className="">
            
                <MDBCardGroup>
                        <MDBCard className='gradient-orange text-white'>
              
                    <MDBCardBody>
                                <MDBCardTitle >    <MDBTypography tag='h3' className='mb-0'>
                                   MENANG  
                                    <br /> <MDBTypography tag='small' className='text-black-40'>
                                        HADIAH NYA
                                    </MDBTypography>
                                </MDBTypography>
                                </MDBCardTitle>
                        <MDBCardText>
                                Lihat hadiah yang tersedia untuk ditukar dengan poin Anda
               
                        </MDBCardText>
                    </MDBCardBody>
                    </MDBCard>
                
             
                        <MDBCard className='gradient-blue text-white'>
                
                        <MDBCardBody>
                                <MDBCardTitle className="text-white">    <MDBTypography tag='h3' className='mb-0'>
                                IKUTI 
                                    <br /> <MDBTypography tag='small' className='text-black-40'>
                                    TANTANGAN NYA
                                </MDBTypography>
                            </MDBTypography>
                            </MDBCardTitle>
                            <MDBCardText>
                                Setiap klik di web ini dapat menghasilkan poin untuk ditabung
                              
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
             
                        <MDBCard className='gradient-purple text-white'>
             
                        <MDBCardBody>
                                <MDBCardTitle className="text-white">    <MDBTypography tag='h3' className='mb-0'>
                                KOMPAK 
                                    <br /> <MDBTypography tag='small' className='text-black-40'>
                                    KELUARGA
                                </MDBTypography>
                            </MDBTypography>
                            </MDBCardTitle>
                            <MDBCardText>
                                Disini adalah tempat terbaik untuk kita mengenal keluarga dan bertumbuh bersama
                            </MDBCardText>
                        </MDBCardBody>
                    </MDBCard>
                </MDBCardGroup>
    
                </MDBRow>
         

  </>
)


    
  
}






