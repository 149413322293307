import React, { Component, useContext, useState, useEffect, useReducer, useRef } from 'react';
import { MDBContainer, MDBTypography, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTextArea, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Text } from './Text';
import { Spinner } from "./fungsi/Spinner";
import reducer from "./konteks/reducer";
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext';
import { useHistory, useLocation } from "react-router-dom";
import { Iklan } from './Iklan';
import { TikTok } from "react-tiktok";
import useToggle from './fungsi/useToggle';
import renderHTML from './fungsi/renderHTML';


export function ArtikelDetail(props) {
    const { user, changeUser } = useContext(UserContext)
    const param = props.artikelid && props.artikelid 
    const halaman = props.halaman && props.halaman
    const [error, onError] = useToggle()
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const [dataKu, setDataKu] = useState([])
   // const [hasData, sethasData] = useState(true)
    const loadData = async (formValue) => {
   
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/turnambilartikeldetail/'+ formValue.artikelid+"/"+formValue.halaman, {
            method: 'GET'
        });
        if (response.status !== 200) {
           // sethasData(false)
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
             throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setDataKu(data);
        dispatch({ type: "HAS_DATA" })
        dispatch({ type: "FINISH_LOADING" })
       //changeUser({ ...data[0] });

    }
    useEffect(
        () => {
            let timer1 = setTimeout(() =>loadData(props), 1000);

           
            return () => {
                clearTimeout(timer1);
            };
        },
        // useEffect will run only one time with empty []
        // if you pass a value to array,
        // like this - [data]
        // than clearTimeout will run every time
        // this value changes (useEffect re-run)
        []
    );


    if (isLoading) return (
        <Spinner />
    )
    return (<>


        {error ? (
            // eslint-disable-next-line react/no-unknown-property
            <p onError={onError()}></p>
        ) : (dataKu.map((d, i) => (
            <p key="artikeldetailhalaman+{d.urutan}" >
                {i == 4 && (<p className="border-start  border-warning  border-5 px-2  py-2 text-primary" >
 Survey yg relevan :
                </p>)}

                {(d.isbold > 0) ? (<strong> {d.uraian}</strong>) : (renderHTML(d.uraian))}

                {i == (dataKu.length - 1) && (<p className="border-start  border-warning  border-5 py-2 my-1 px-2 text-primary" >
                    Baca juga :
                </p>)}

                {(d.urlimage.length > 5) ? (<img className="w-100 rounded-6 mb-1" src={d.urlimage} alt={d.judul } />) : ("")}
            </p>

        )))}


        </>
  




    );

}