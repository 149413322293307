
import React, { Component, useContext, useState, useEffect, useReducer, useRef } from 'react';
import { MDBContainer, MDBRow, MDBCol, MDBCard, MDBCardBody, MDBTextArea, MDBIcon, MDBView, MDBBtn, MDBInput, MDBValidation, MDBValidationItem } from "mdb-react-ui-kit";
import { Text } from './Text'
import { TodayQuiz } from './todayQuizAdaJudul'
import { Spinner } from "./fungsi/Spinner"
import reducer from "./konteks/reducer"
import { useForm, Controller } from "react-hook-form";
import UserContext from './konteks/userContext'
import { MDBWysiwyg } from 'mdb-react-wysiwyg';
import { MDBTableEditor } from 'mdb-react-table-editor';
import { useHistory, useLocation } from "react-router-dom";
import { FancyEditor } from './FancyEditor'


export function Artikel(props) {
    const { user, changeUser } = useContext(UserContext)
    const [isform, setisform] = useState(false);
    let [param,setParam] = useState( props.match.params.aksi && props.match.params.aksi)

    const [dataQuiz, setdataQuiz] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [description, setDescription] = useState('');
    let history = useHistory();
    const initialState = {
        isLoading: false,
        hasError: false,
        pesanError: "",
        hasDetail: false,
        hasData: false,
        isLogin: user.islogedin,
        notAuth: !user.islogedin,
        data: [],
        dataDetail: [],
        warnaPesan: "warning"
    }
    function nextQuiz() {
        // console.log(quizIndex);
        setQuizIndex(i => (i + 1) % dataQuiz.length);
    }
    const defaultVal = {
        id: "0",
       judul: '',
       tags: "",
        deskripsi1: "",
        deskripsi2: "",
       imgheader:""

    };
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm({ defaultValues: defaultVal });
    const { handleSubmit:handleSubmit2 } = useForm();
    const [state, dispatch] = useReducer(reducer, initialState);
    const { isLoading, hasError, pesanError, hasData, hasDetail, isLogin, notAuth, data, dataDetail } = state
    const fancyEditor = useRef(null);
    const { ref, ...rest } = register('isi');
    useEffect(() => {
        switch (param) {
            case "entri":
                setisform(true);
                // code block
                break;
            case "pilih":
                setisform(false);
                saveFormData(defaultVal);
                  // code block
                break;
            case "":
                break;
            default:
            //   console.log("");
            // code block
        }
    }, []);



    const saveFormData = async (formValue) => {
      //  const wysiwygElement = document.getElementsByClassName('wysiwyg')[0];
     //   const wysiwygInstance = WYSIWYG.getInstance(wysiwygElement);
        //console.log(description);
        const formNet = {
            ...formValue, "memberid": user.id, "id": 0, "uraian": encodeURIComponent(formValue.isi)
        }
        delete formNet.isi;

        //console.log(formNet);
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/simpanartikel', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setdataQuiz(data);
        setisform(false);
        dispatch({ type: "FINISH_LOADING" })
        setParam("pilih");
        //changeUser({ ...data[0] });

    }
    const hapusData = async (formValue) => {
        const formNet = {
            ...formValue, "memberid": user.id
        }
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/deleteartikel/'+formNet.id, {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
        const data = await response.json();
        setdataQuiz(data);
        dispatch({ type: "FINISH_LOADING" })
        //changeUser({ ...data[0] });

    }
    const updateData = async (formValue) => {
        const formNet = {
            ...formValue, "memberid": user.id, "isactive": formValue.isactive  ? 1 : 0 
        }
        dispatch({ type: "START_LOADING" })
        const response = await fetch('https://ibw.news/updateartikel/' + formNet.id, {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formNet)
        });
        if (response.status !== 200) {
            dispatch({ type: "FINISH_LOADING" }) // setIsLoading(false);
            dispatch({ type: "HAS_ERROR", payload: ` ${response.status}` })//  setHasError(true);
            //  throw new Error(` ${response.status}`);
        }
      
        const data = await response.json();
        setdataQuiz(data);
        dispatch({ type: "FINISH_LOADING" });
        if (response.status == 200) {
            reset({ defaultVal });
        }
        //changeUser({ ...data[0] });

    }

    const onSubmit = async (data, e) => {
        e.preventDefault();
            if (e.nativeEvent.submitter.id == 'submitButton') {

                try {
                    //setSubmit(true);
                    await saveFormData(data);
                   

                } catch (e) {
                    // setPesanError(`Gagal Jawab ${e.message}`);
                    // alert(`Login failed! ${e.message}`);
                    dispatch({ type: "FINISH_LOADING" })
                    dispatch({ type: "HAS_ERROR", payload: `Gagal Jawab ${e.message}` })
                }
            
        }
    


    }

    // console.log(dataQuiz);
    if (isLoading) return (
        <Spinner />
    )
    function TabelKu(props) {
        const [loading, setLoading] = useState(false);

        const [asyncData, setAsyncData] = useState({
            columns: [
                { label: 'id', field: 'id' ,  inputType: 'number' },
                { label: 'judul', field: 'judul' },
                { label: 'deskripsi 1', field: 'deskripsi1' },
                { label: 'deskripsi 2', field: 'deskripsi2' },
                { label: 'tags', field: 'tags' },
                { label: 'img header', field: 'imgheader' },
                { label: 'teaser', field: 'teaser' },
            ],
            rows: props.data,
        });

        useEffect(() => {
            if (loading && asyncData.rows.length === 0) {
                fetch('https://ibw.news/simpanartikel', {
                    method: 'POST', headers: { 'Content-type': 'application/json' },
                    body: JSON.stringify(defaultVal)
                })
                    .then((response) => response.json())
                    .then((data) =>
                          setTimeout(() => {
                            setAsyncData({
                                columns: asyncData.columns,
                                rows: data.map((d,i) => ({
                                    ...d, "isactive": d.isactive === 1 ? true : false, "imgheader": "<a href='" + d.imgheader + "'>" + d.imgheader+"</a>"
                                })),
                            });
                        }, 3000)
                    );
            }
        }, [loading, asyncData.rows]);

        useEffect(() => {
            if (asyncData.rows.length === 0) {
                setLoading(true);
            } else {
                setLoading(false);
            }
        }, [asyncData.rows]);

        const [modalData, setModalData] = useState({
            columns: asyncData.columns,
            rows: asyncData.rows,
        });

        function onAdd(newRow) { setAsyncData({ ...asyncData, rows: [...asyncData.rows, newRow] }) }
        function onModify(modifiedData) { console.log(modifiedData); setAsyncData({ ...asyncData, rows: modifiedData }) }
        function hapus(id) { hapusData(asyncData.rows.filter((row, i) => i === id)) }
        return (
            <>
                <MDBBtn className='mb-4' onClick={() => setAsyncData({ ...asyncData, rows: [] })}>
                    Reload data
                    <MDBIcon icon='sync' className='ms-2' />
                </MDBBtn>

                <MDBTableEditor striped
                    selectable
                    isLoading={loading}
                    mode='modal'
                    data={asyncData}
                    entriesOptions={[5, 10, 15]}
                    entries={5}
                    onAdd={(newRow) => { setAsyncData({ ...asyncData, rows: [...asyncData.rows, newRow] }); saveFormData(newRow); }}
                    onModify={(modifiedData, i) => { console.log(modifiedData[i]); setAsyncData({ ...asyncData, rows: modifiedData }); updateData(modifiedData[i]) }}
                    onDelete={(id) => { setAsyncData({ ...asyncData, rows: asyncData.rows.filter((row, i) => i !== id) }); hapusData(asyncData.rows[id]) }}
                    onRowClick={(row) => window.open("https://ibw.news/artikel/" +encodeURIComponent(row.judul), "_blank")}
                />
            </>
        );
    }
    //{ setAsyncData({ ...asyncData, rows: modifiedData })};
    const ubah = (data) => { setDescription(data);console.log(data) }
 

    return (
        <>


            <MDBContainer >
                <h1 className='my-3 text-center'><Text tid={props.match.params.aksi && props.match.params.aksi} />  <Text tid="Artikel" /> </h1>
             
           
                    {isform && (
                    <>
                        <MDBCard>
                            <MDBCardBody>
                                <form onSubmit={handleSubmit(onSubmit)} className="" >
                            <Controller
                                name="judul"
                                control={control}
                                rules={{ required: true, minLength: 4 }}
                                render={({ field }) => <MDBInput className="mb-4" label="judul" {...field} />}
                            />
                            {errors.judul?.type === 'minLength' && "Judul harus diisi"}
                            <Controller
                                name="imgheader"
                                        control={control}
                                        rules={{ required: true, minLength: 4 }}
                                        render={({ field }) => <MDBInput  className="mb-4" label="Url Image (" {...field} /> }
                                    />
                                    {errors.imgheader?.type === 'minLength' && "URL Image harus diisi"}               
                                    <Controller
                                        name="teaser"
                                        control={control}
                                        rules={{ required: true, minLength: 4 }}
                                        render={({ field }) => <MDBInput className="mb-4" label="Teaser" {...field} />}
                                    />   
                                    <Controller
                                        name="deskripsi1"
                                        control={control}
                                        rules={{ required: true, minLength: 4 }}
                                        render={({ field }) => <MDBTextArea rows={4} className="mb-4" label="Uraian" {...field} />}
                                    />   
                                    {errors.deskripsi1?.type === 'minLength' && "Deskripsi 1 harus diisi"}   
                                    <Controller
                                        name="deskripsi2"
                                        control={control}
                                        rules={{ required: true, minLength: 4 }}
                                        render={({ field }) => <MDBTextArea rows={4} className="mb-4" label="Uraian" {...field} />}
                                    />  
                                    {errors.deskripsi2?.type === 'minLength' && "Deskripsi 2 harus diisi"}   
                                    <Controller
                                        name="tags"
                                        control={control}
                                        render={({ field }) => <MDBInput className="mb-4" label="Tags pisahkan dengan koma" {...field} />}
                                    />                                       
                                    {errors.tags?.type === 'minLength' && "Tags harus diisi"}   

         
                                    <MDBBtn color="primary" block type="submit" id="submitButton"  > Simpan </MDBBtn>
                    {dataQuiz.length > 1 && <MDBBtn color="none" tag="a" block type="button" onClick={nextQuiz}  > Next Quiz </MDBBtn>}
                                </form> </MDBCardBody>
                            </MDBCard>
                        </>
                        
                )}


                { (!isform && dataQuiz.length>0  ) && <TabelKu data={dataQuiz } />}
                <hr />
                <form onSubmit={handleSubmit2((data, e) => e.preventDefault())} id="test"  >

                    <MDBWysiwyg className="mb-4" label="Editor" >

                    </MDBWysiwyg >


                </form>
            </MDBContainer>

        </>
    )




}