// it provides the language context to app
import React, {
    useState 
} from 'react';
import { languageOptions, dictionaryList } from './languages';
import { LanguageContext } from './LanguageContext';

export function LanguageProvider({ children }) {
    const [userLanguage, setUserLanguage] = useState('id');
   // const [userLanguage, setUserLanguage] = useState(defaultLanguage || 'id');
    const provider = {
        userLanguage,
        dictionary: dictionaryList[userLanguage],
        userLanguageChange: selected => {
            const defaultLanguage = window.localStorage.getItem('rcml-lang');
            setUserLanguage(defaultLanguage || 'id')
           // console.log(selected)
            const newLanguage=selected
            setUserLanguage(newLanguage);
            window.localStorage.setItem('rcml-lang', newLanguage);
        }
    };
    return (
        <LanguageContext.Provider value={provider}>
            {children}
        </LanguageContext.Provider>
    );
}