import React from 'react';
import {
    MDBTypography
} from 'mdb-react-ui-kit';
import { MDBCard, MDBCardHeader, MDBCardBody, MDBCardTitle, MDBCardText, MDBCardFooter, MDBBtn, MDBRow, MDBCol, MDBCardImage, MDBIcon, MDBInput } from 'mdb-react-ui-kit';


export function PeragaAnakRingkas() {
    return (     
        <>
            <div className='text-center mb-5'>
                <MDBTypography tag='h1' className='mb-0 pt-5'>
                    Jadilah Bagian dari Komunitas yang Berdaya

                </MDBTypography>
                <MDBTypography tag='small' className='text-muted '>
                    * pendampingan anak jadi menyenangkan *
                </MDBTypography>
            </div>
            <MDBCard className='mb-5 mx-2 '>
                <MDBCardBody>
                    <h2 className='h1-responsive font-weight-bold text-center my-5'>
                        Kumpulan Alat Peraga
                    </h2>
                    <p className='text-center w-responsive mx-auto mb-5'>
Ada banyak video tentang bermain bersama anak di internet, namun seringkali kita kesulitan menemukan bahan-bahan nya. Mari bersama kita permudah kegiatan ini dengan menyediakan secara bersama-sama. Dari kita untuk kita agar bersama menjadi berdaya
                    </p>
                    <form className='d-flex input-group w-auto  align-items-center mb-2'>
                        <input type='search' className='form-control' placeholder='Kopi kan link postingan' aria-label='Search' />
                        <MDBBtn color='primary'  outline rounded >Search dari Link</MDBBtn>
                    </form>
                    <MDBRow className='mb-2'  >
                        <MDBCol lg='5' xl='4'>
                         
                                <img
                                    className='img-fluid'
                                src='https://ibw.news/img/kapal.jpg'
                                    alt=''
                                />
                                <a href='#!'>
                             
                                </a>
                     
                        </MDBCol>
                        <MDBCol lg='7' xl='8'>
                            <h3 className='font-weight-bold mb-3 p-0'>
                                <strong>Mainan Kapal dari #kardus</strong>
                            </h3>
                            <p className='dark-grey-text'>
                                Nam libero tempore, cum soluta nobis est eligendi optio cumque
                                nihil impedit quo minus id quod maxime placeat facere
                                possimus, omnis voluptas assumenda est, omnis dolor
                                repellendus et aut officiis debitis cum soluta nobis est
                                eligendi placeat facere aut rerum.
                            </p>
                            <p>
                                by{' '}
                                <a href='#!' className='font-weight-bold'>
                                    Jessica Clark
                                </a>
                                , 19/04/2018
                            </p>
                            <MDBBtn color='primary' size='md'>
                                Read More
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                    <MDBRow className='mb-2' >
                        <MDBCol lg='5' xl='4'>
                          
                                <img
                                    className='img-fluid'
                                    src='https://ibw.news/img/celana.jpg'
                                    alt=''
                                />
                                <a href='#!'>
                                 
                                </a>
                       
                        </MDBCol>
                        <MDBCol lg='7' xl='8'>
                            <h3 className='font-weight-bold mb-3 p-0'>
                                <strong>#menjahit celana pendek anak</strong>
                            </h3>
                            <p className='dark-grey-text'>
                                At vero eos et accusamus et iusto odio dignissimos ducimus qui
                                blanditiis praesentium voluptatum deleniti atque corrupti quos
                                dolores et quas molestias excepturi sint occaecati cupiditate
                                non provident et accusamus iusto odio dignissimos et dolorum
                                fuga.
                            </p>
                            <p>
                                by{' '}
                                <a href='#!' className='font-weight-bold'>
                                    Jessica Clark
                                </a>
                                , 16/04/2018
                            </p>
                            <MDBBtn color='primary' size='md'>
                                Read More
                            </MDBBtn>
                        </MDBCol>
                    </MDBRow>

                </MDBCardBody>
            </MDBCard>

        </>
       
    )
}