import React, { useState, useContext, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBCardText, MDBCardBody, MDBCardHeader, MDBContainer, MDBInput } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
import { Dialog }  from './Dialog'
import { Route } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { ArtikelPerKategori } from './ArtikelPerKategori'
export function Parent() {
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const { user, changeUser } = useContext(UserContext)
    const isAuth = user.islogedin;
    const [isloading, setIsLoading] = useState(false);
    const [haserror, setHasError] = useState(false);
    const [pesanerror, setPesanError] = useState('');
    const [dataQuiz, setdataQuiz] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    const [jawaban, setJawaban] = useState('');
    let history = useHistory();
    const defaultValues = {
        jawaban: ""
    };
    const location = useLocation();
    const [basicModal, setBasicModal] = useState(false);

    const toggleShow = () => setBasicModal(!basicModal);
    const onClick = (url) => {
       
        history.push(url);

    }
   
    useEffect(() => {
      //  console.log(dataAksi)
      //  setdataQuiz(dataAksi);
    }, []);







    return (
        <>
                  
  
                        <ArtikelPerKategori kategori="parenting" />
                     
                     
          
            </>)
}


