import React, { useState, useContext, useEffect } from 'react';
import {
    MDBContainer,
    MDBNavbar,
    MDBNavbarBrand,
    MDBNavbarToggler,
    MDBIcon,
    MDBNavbarNav,
    MDBNavbarItem,
    MDBNavbarLink,
    MDBBtn,
    MDBDropdown,
    MDBDropdownToggle,
    MDBDropdownMenu,
    MDBDropdownItem,
    MDBDropdownLink,
    MDBCollapse
} from 'mdb-react-ui-kit';

import UserContext from './konteks/userContext'
import Avatar from './Avatar';
import { useHistory } from "react-router-dom";
import { useMDBCookies } from 'mdb-react-cookies-management';
import { LanguageProvider } from './konteks/LanguageProvider';
import { LanguageSelector } from './LanguageSelector';


export function NavMenuMDB() {
    const { user, changeUser } = useContext(UserContext)
    const [CookieUser, { setItem: setCookieUser, removeItem: removeItemCookieUser }] = useMDBCookies('user',)
    const [userku, setUserku] = useState(user);
    const onLogout = () => { setShowBasic(!showBasic); changeUser({}); removeItemCookieUser() }
    const onLogin = () => {
        setShowBasic(!showBasic);
        history.push("/login" );
        return false
    }
    const onHome = () => {
        setShowBasic(!showBasic);
        history.push("/");
        return false
    }
    const isAuth = user.islogedin;
  
    const [showBasic, setShowBasic] = useState(false);
    let history = useHistory();
    
   // const [isAuth, setisAuth] = useState(userku.islogedin);
    useEffect(() => { setUserku(user); },[]);
 //setisAuth(userku.islogedin);  <MDBNavbarBrand href='#'>IBW</MDBNavbarBrand>


    return (
        <>
            <MDBNavbar expand='lg' light bgColor='light'>
                <MDBContainer fluid>
                    <MDBNavbarBrand  href='#'>IBW</MDBNavbarBrand>
                   

                    <MDBNavbarToggler
                        aria-controls='navbarSupportedContent'
                        aria-expanded='false'
                        aria-label='Toggle navigation'
                        onClick={() => setShowBasic(!showBasic)}
                    >
                        <MDBIcon icon='bars' fas />
                    </MDBNavbarToggler>

                    <MDBCollapse navbar show={showBasic}>
                        <MDBNavbarNav className='mr-auto mb-2 mb-lg-0'>
                            <MDBNavbarItem>
                                <MDBNavbarLink active aria-current='page' href='/'>
                                    Home
                                </MDBNavbarLink>
                              
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='#'>Facebook Group</MDBNavbarLink>
                            </MDBNavbarItem>
                            <MDBNavbarItem>
                                <MDBNavbarLink href='#'>Instagram</MDBNavbarLink>
                            </MDBNavbarItem>
                        
                            <form className='d-flex input-group w-auto invisible'>
                                <input type='search' className='form-control' placeholder='Cari konten' aria-label='Search' />
                                <MDBBtn color='primary'>Search</MDBBtn>
                            </form>
                          
                        </MDBNavbarNav>
                        <LanguageSelector  />
                  
                        {isAuth ? (<> <Avatar /> <MDBBtn onClick={onLogout}>Logout </MDBBtn> </>) :
                            (<MDBNavbarLink onClick={onLogin}>Login </MDBNavbarLink >) }
                       
          
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>


       </>
    );
}




























