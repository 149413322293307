import React, { useState, useContext, useEffect } from 'react';
import { MDBRow, MDBCol, MDBCard, MDBBtn, MDBStepper, MDBStepperStep } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
import { Dialog }  from './Dialog'
import { Route } from 'react-router';
import { useForm, Controller } from "react-hook-form";
import { useHistory, useLocation } from "react-router-dom";
import { dataAksi } from "./data/dataadmin"
import { Quiz } from "./Quiz"
import { Kepribadian } from "./Kepribadian"
import { Artikel } from "./Artikel"
export function Admin() {
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const { user, changeUser } = useContext(UserContext)
    const isAuth = user.islogedin;
    const [dataQuiz, setdataQuiz] = useState([])
    const [quizIndex, setQuizIndex] = useState(0);
    let history = useHistory();
    const defaultValues = {
        jawaban: ""
    };
    const location = useLocation();
    const [basicModal, setBasicModal] = useState(false);
   // console.log(location)
    const toggleShow = () => setBasicModal(!basicModal);
    const onClick = (url) => {
       
        history.push(url);
        return false;
    }
    // { match, location, history }
    const QuizComp = ({ match }) => (
        <div>
            <Route path={match.url + "/:aksi"} component={Quiz} >
               
            </Route>
        </div>
    );
    const KepribadianComp = ({ match }) => (
        <div>
            <Route path={match.url + "/:aksi"} component={Kepribadian} >

            </Route>
        </div>
    );
    const ArtikelComp = ({ match }) => (
        <div>
            <Route path={match.url + "/:aksi"} component={Artikel} >

            </Route>
        </div>
    );
    useEffect(() => {
      //  console.log(dataAksi)
       // setdataQuiz(dataAksi);
    }, []);


    function SubJudul (props) {
        const a =props.data
      
        return (
            <>

                <MDBStepperStep headIcon={props.step} headText={a.label} itemId={props.step}>
                    {a.aksi.map((d, i) => (
                       
                            <MDBBtn key={"aksi"+i }  outline rounded className='mx-2' color='secondary' onClick={(e) => { e.preventDefault; props.onClick(d.url) }} >
                                {d.label}
                            </MDBBtn>
                        
                        ))}
                </MDBStepperStep>

           </>
        );

    }




    return (
        <>
            <Route exact path={"/admin/quiz" + "/:aksi"} component={Quiz} />
            <Route exact path={"/admin/kepribadian"+ "/:aksi"} component={Kepribadian} />
            <Route exact path={"/admin/artikel" + "/:aksi"} component={Artikel} />

                 <MDBStepper  type='vertical'>    
             
                {dataAksi.map((d, i) =>
                (<React.Fragment key={"fragment" + i} >
                 
                
                        <SubJudul key={"step" + i} step={i + 1} data={d} onClick={onClick} />

                  
                </React.Fragment>
                ))
                }

            </MDBStepper>

           
          
            </>)
}


