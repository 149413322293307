import React, { useContext} from 'react';
import {  MDBBtn,MDBIcon } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
import { useHistory } from "react-router-dom";

export function ButtonAuth(props) {
    const { color = "primary", title= "Klik", href = "#" } = props;

    const { user, changeUser } = useContext(UserContext)
    let history = useHistory();
    const handleClick = (event) => {
        event.preventDefault();
        !user.islogedin ? history.push("/login") :
            history.push(href)
            ;
    };


    return (
        <MDBBtn tag='a' href={{ href }}  color={{ color }} onClick={handleClick} {...props} >
            {props.children}
        </MDBBtn>
        
        )
}