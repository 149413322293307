import React, { useState } from 'react';

const useToggle = (initialStatus = false) => {
    const [status, setStatus] = useState(initialStatus)

    const toggle = (newStatus) => () => {
        if (newStatus === undefined) {
            setStatus(status => !status)
        } else {
            setStatus(newStatus)
        }
    }

    return [status, toggle]
}// JavaScript source code

export default useToggle;
