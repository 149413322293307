import React, { useRef, useState, useContext, useEffect } from "react";
import {
  MDBContainer,
  MDBRow,
  MDBCard,
  MDBCol,
  MDBCardBody,
  MDBTabs,
  MDBTabsItem,
  MDBTabsLink,
  MDBTabsPane,
    MDBTabsContent, MDBValidation, MDBValidationItem,
    MDBSelect, MDBTooltip,
  MDBCheckbox,
    MDBInput, MDBAlert,
    MDBBtn, MDBBtnGroup, MDBRadio
} from "mdb-react-ui-kit";
import UserContext from './konteks/userContext'
import { useHistory } from "react-router-dom";
import { useMDBCookies } from 'mdb-react-cookies-management';
import { Spinner } from "./fungsi/Spinner"
import { useForm, Controller } from "react-hook-form";

const Login = () => {
    const [fillActive, setFillActive] = useState("tab-register");
    const { register, control, reset, handleSubmit, watch, formState: { errors } } = useForm();
    const { register:register2, control:control2, reset:reset2, handleSubmit:handleSubmit2, watch:watch2, formState: { errors:errors2 } } = useForm();
    const { user, changeUser } = useContext(UserContext)
    const [CookieUser, { setItem: setCookieUser, removeItem: removeItemCookieUser }] = useMDBCookies('user')
    const [isloading, setIsLoading] = useState(false);
    const [haserror, setHasError] = useState(false);
    const [pesanerror, setPesanError] = useState('');
    const [email, setEmail] = useState('');
    let history = useHistory();

   // console.log(watch("nama")); // watch input value by passing the name of it
     
    const handleFillClick = (value) => {
        setHasError(false);
    if (value === fillActive) {
      return;
      }
      
    setFillActive(value);
  };

    const onSubmit = async( data) => {
      //  console.log(data);
        try {
            await saveFormDataReg(data);
           // setSubmit(!issubmit);
            alert('Your registration was successfully submitted!');
            setIsLoading(false);
            history.push("/");
        } catch (e) {
           // setIsLoading(false);
           // setisValidated(false);
           // setPesanError(`Login failed! ${e.message}`);
           // setHasError(true);
        }

   
    }
    const onSubmitLogin = async (data) => {
         // console.log(data);
        try {
            await saveFormData(data);
            // setSubmit(!issubmit);
            alert('Login Success!');
            setIsLoading(false);
            history.push("/");
        } catch (e) {
            // setIsLoading(false);
            // setisValidated(false);
            // setPesanError(`Login failed! ${e.message}`);
            // setHasError(true);
        }


    }

    const saveFormDataReg = async (formValue) => {
        //   setFormValue({
        //        "nama": nama, "wa": wa, "email": email, "grup": grup, "gender": gender
        //    });
        setIsLoading(true);
        const response = await fetch('https://ibw.news/mendaftar', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formValue)
        });
        if (response.status !== 200) {
          //  setHasError(true);
            setIsLoading(false);
            throw new Error(`Request failed: ${response.statusText}`);
        }
        const data = await response.json();
        changeUser({ ...data[0] });
        setCookieUser(data[0])
       // console.log(CookieUser);
       //  setCookieUser({ ...data[0] })
       //console.log(user);



    }


    const saveFormData = async (formValue) => {
        //   setFormValue({
        //        "nama": nama, "wa": wa, "email": email, "grup": grup, "gender": gender
        //    });
        //console.log(JSON.stringify(formValue));
        setIsLoading(true);
        const response = await fetch('https://ibw.news/masuk', {
            method: 'POST', headers: { 'Content-type': 'application/json' },
            body: JSON.stringify(formValue)
        });
        if (response.status !== 200) {
            //  setHasError(true);
            setIsLoading(false);
            throw new Error(`Request failed: ${response.statusText}`);
        }
        const data = await response.json();
        changeUser({ ...data[0] });
        setCookieUser(data[0])
        //console.log(CookieUser);



    }


    return (
        <>
            {isloading ? (< Spinner />) : (
                <MDBContainer className="pt-5">
                    <MDBRow className="d-flex justify-content-center">
                        <MDBCol md="6">
                            <MDBCard>
                                <MDBCardBody className="p-4">
                                    <MDBTabs pills fill className="mb-3">
                                        <MDBTabsItem>
                                            <MDBTabsLink
                                                onClick={() => handleFillClick("tab-login")}
                                                active={fillActive === "tab-login"}
                                            >
                                                Login
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                        <MDBTabsItem>
                                            <MDBTabsLink
                                                onClick={() => handleFillClick("tab-register")}
                                                active={fillActive === "tab-register"}
                                            >
                                                Register
                                            </MDBTabsLink>
                                        </MDBTabsItem>
                                    </MDBTabs>

                                    <MDBTabsContent>
                                        <MDBTabsPane
                                            show={fillActive === "tab-login"}
                                            aria-labelledby="tab-login"
                                        >
                                            <form onSubmit={handleSubmit2(onSubmitLogin)}>
                                                <Controller
                                                    name="email"
                                                    control={control2}
                                                    rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                                                    render={({ field }) => <MDBInput type="email" className="mb-4" label="Email" {...field} />}
                                                />

                                                {errors2.email?.type === 'pattern' && "Masukkan email yang valid"}

                                                <Controller
                                                    name="kode"
                                                    control={control2}
                                                    rules={{ required: true, minLength: 3 }}
                                                    render={({ field }) => <MDBInput className="mb-4" label="Kode" {...field} />}
                                                />

                                                {errors2.kode?.type === 'minLength' && "Kode Aktivasi harus diisi"}



                                                <MDBCol md="6" className="d-flex justify-content-center">
                                                    <MDBBtn tag="a" color='none' className="mb-4" onClick={() => window.open("https://api.whatsapp.com/send/?phone=6285646555420&text=%23aktivasi")}>
                                                        <MDBTooltip className="mb-4" wrapperProps={{ color: 'warning' }} title='Isi dulu Emailnya ya'>
                                                            Dapatkan kode via WA (setelah register ya)
                                                        </MDBTooltip>
                                                    </MDBBtn>
                                                </MDBCol>


                                                <MDBBtn type="submit" block className="mb-4">
                                                    Sign in
                                                </MDBBtn>

                                                <div className="text-center">

                                                    Not a member?  <MDBBtn tag="a" color='none' onClick={() => handleFillClick("tab-register")}>Register</MDBBtn>

                                                </div>


                                            </form>

                         
                                        </MDBTabsPane>
                                        <MDBTabsPane
                                            show={fillActive === "tab-register"}
                                            aria-labelledby="tab-register"
                                        >
                                            <form onSubmit={handleSubmit(onSubmit)}>

                                                <Controller
                                                    name="nama"
                                                    control={control}
                                                    rules={{ required: true, minLength: 3 }}
                                                    render={({ field }) => <MDBInput className="mb-4" label="Nama" {...field} />}
                                                />

                                                {errors.nama?.type === 'minLength' && "Nama harus diisi"}

                                                <Controller
                                                    name="wa"
                                                    control={control}
                                                    rules={{ required: true, pattern: /^.*62.*$/ }}
                                                    render={({ field }) => <MDBInput type="number" className="mb-4" label="Whatsapp" {...field} />}
                                                />
                                                   {errors.wa?.type === 'pattern' && "Nomor Whatsapp harus diawali dengan 62"}


                                                <Controller
                                                    name="email"
                                                    control={control}
                                                    rules={{ required: true, pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i }}
                                                    render={({ field }) => <MDBInput type="email" className="mb-4" label="Email" {...field} />}
                                                />

                                                 {errors.email?.type === 'pattern' && "Masukkan email yang valid"}

                                                <select className="form-select mb-4" {...register("gender", { required: true })}>
                                                        <option value="p">Perempuan</option>
                                                        <option value="l">Laki-Laki</option>
                                                     
                                                    </select>
                                                {errors.gender?.type === 'required' && "Pilih Gender"}
                                    

                                                <select className="form-select mb-4" {...register("grup", { required: true })}>
                                                    <option value="blossom">Blossom</option>
                                                    <option value="peanut">Peanut</option>

                                                </select>
                                                {errors.nama?.type === 'required' && "Grup harus diisi"}

                                        

                                                <MDBBtn type="submit"  block className="mb-3" >
                                                    Mendaftar
                                                    </MDBBtn>
                                                    </form>
                                           
                                        </MDBTabsPane>
                                    </MDBTabsContent>
                                </MDBCardBody>
                            </MDBCard>
                        </MDBCol>
                    </MDBRow>
                </MDBContainer>)}

            { haserror &&  <MDBAlert show className='w-100' color='warning'>
                {pesanerror }
            </MDBAlert>}
            </>
  );
};

export default Login;
