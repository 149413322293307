
import React, { useState, useContext, useEffect, useRef } from 'react';


import { MDBCard, MDBCardBody, MDBCardTitle, MDBCardText, MDBRow, MDBCol, MDBBtn, MDBAlert, MDBListGroup, MDBListGroupItem } from 'mdb-react-ui-kit';
import UserContext from './konteks/userContext'
import { Spinner } from "./fungsi/Spinner"


export function GrupRekap() {
    const [data, setData] = useState('');
    const { user, changeUser } = useContext(UserContext);
    const [hasData, sethasData] = useState(false)
    const [Loading, setLoading] = useState(true)
    const [pesanerror, setPesanError] = useState('');
    const [haserror, setHasError] = useState(false);
    const [notAuth, setNotAuth] = useState(!user.islogedin)

    useEffect(() => {
        const userid = !notAuth ? user.id : 0
        function populateData() {
            const response = fetch('getnotif/').then(
                res => {
                    if (res.ok) return res.json();
                    setHasError(true); setPesanError("ada error " + res.status)
                }
            ).then(data => { setData(data); sethasData(true); setLoading(false); setHasError(false) }
            );

        }
        populateData();
    }
        , []);

    if (Loading) return (
        <Spinner />
    )

    function CardNotif(props) {
        const d = props.data;

        return (<MDBCol key={ d.uraian}  >
            <MDBCard alignment='center'>
                <MDBCardBody>
                    <MDBCardTitle color="warning">{d.uraian != "" ? d.uraian : "Belum Pilih Grup"  }</MDBCardTitle>
                   
                        <MDBListGroup flush>
                            <MDBListGroupItem> Mendaftar : {d.hitung}  </MDBListGroupItem>
                            <MDBListGroupItem> Member Aktif : {d.hitungnett}</MDBListGroupItem>
                            <MDBListGroupItem> Total Poin : {d.jumlah}  </MDBListGroupItem>
                        </MDBListGroup>
                        
                          
                       

                   
                    <MDBBtn disabled href={'/' + d.uraian}>Detail</MDBBtn>
                </MDBCardBody>
            </MDBCard>
        </MDBCol>)

    }
    return (<>
        <MDBRow>
         
        {hasData && data.map((d, i) => (
            <CardNotif index={i} key={"cardnotif"+i} data={d} />
            
        ))}
        </MDBRow>
       
        {haserror && <MDBAlert show className='w-100' color='warning'>
            {pesanerror}
        </MDBAlert>}
        </>
    );
}